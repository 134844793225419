import { render, staticRenderFns } from "./FilesList.vue?vue&type=template&id=0b02b962&scoped=true"
import script from "./FilesList.vue?vue&type=script&lang=js"
export * from "./FilesList.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b02b962",
  null
  
)

export default component.exports