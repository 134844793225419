<template>
  <div>
    <v-card>
      <v-card-title>My profile</v-card-title>
      <v-card-text>
        <form>
          <v-text-field v-model="user.counterparties[0].name" label="Name"></v-text-field>
          <v-text-field v-model="user.email" label="E-mail"></v-text-field>

          <v-btn color="success" class="mr-4" @click="saveProfile()">
            Save
          </v-btn>
          <v-btn @click="$emit('close')">
            Close
          </v-btn>
        </form>
      </v-card-text>
    </v-card>
    <v-snackbar v-model="savedProfile" :timeout="2000" color="success">
      <v-icon>mdi-check</v-icon> Profile saved!
    </v-snackbar>
  </div>
</template>

<script>
import config from "@/config";
import axios from "@/plugins/axios";

export default {
  props: {
    showProfile: {
      type: Boolean
    },
    user: {
      type: Object
    }
  },
  data() {
    return {
      savedProfile: false
    }
  },
  methods: {
    saveProfile() {
      axios.patch(config.apiUrl + '/api/counterparty/' + this.user.counterparties[0].id,

        { name: this.user.counterparties[0].name }

      ).then((response) => {
        console.log(this.user.counterparties[0].id)
        this.savedProfile = true
      })
        .catch(() => { })
        .finally(() => {


        });

    }
  }
}

</script>