<template>
  <div style="width: 100%">
    <component :is="useSelect ? 'v-combobox' : 'v-autocomplete'"
        class="mini icon-green"
        :items="items"
        :loading="isLoading"
        :value="value"
        :search-input.sync="suggested ? suggested : search"
        :clearable="clearable"
        outlined
        dense
        hide-no-data
        :label="label"
        @click:clear="$emit('clear')"
        @input="onInput($event, 'input')"
        @change="onInput($event, 'change')"
        @update:search-input="updateItems"
        return-object
        v-on:focus="updateItems"
        hide-details="auto"
        item-text="name"
        item-id="id"
        :multiple="multiple"
        :no-filter="true"
        :rules="validationRules"
    >
      <template v-slot:selection="{ attr, on, item, index }">
        <span v-if="index === 0 || item.name.length >= 30">
          <span>{{ item.name ? item.name.substring(0, 30) + " ..." : '-' }}</span>
        </span>
        <span
          v-if="index === 1"
          class="ml-1 grey--text text-caption"
        >
          +{{ value.length - 1 }}
        </span>
      </template>
      <template v-if="!hideAdd" v-slot:append>
        <v-icon :style="((label == 'Sales representants' || label == 'Dispatcher') && ($store.getters.role.includes('sales_representant') || $store.getters.role.includes('dispatcher'))) ? 'pointer-events: none;' : 'pointer-events:all'" @mouseup.stop @click.prevent.stop="showMenu">{{ value ? 'mdi-eye' : 'mdi-plus' }}</v-icon>
        <v-icon class="mx-2" v-if="quickbooks && value && !isLinked" @mouseup.stop @click.self="showQBModel" color="orange">mdi-sync-alert</v-icon>
        <v-icon class="mx-2" v-else-if="quickbooks" color="green">mdi-sync-circle</v-icon>
      </template>
    </component>
    <v-dialog width="800" v-model="showQB" overlay-opacity="0.8" style="z-index: 9999" @click:outside="showQB = false">
      <v-card>
        <v-card-title>
          Sync with Quickbooks
          <v-spacer></v-spacer>
          <v-icon @click="showQB = false">mdi-close</v-icon>
        </v-card-title>
        <v-card-text>
          <QuickbooksSync :type="qbType === 'customer' ? 'customers': qbType + 's'" @getLinked="getLinked" v-if="value && showQB" :account-company-id="accountCompanyId" :counterparty="value" :currency="currency" />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="blacklisted" :timeout="3000" color="error">
      You can`t select this carrier because it is in the blacklist!

      <template v-slot:action="{ attrs }">
        <v-btn text color="white" v-bind="attrs" @click="blacklisted = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axiosInstance from "@/plugins/axios";
import config from "@/config";
import CounterpartyEdit from "@/components/counterparty/CounterpartyEdit";
import QuickbooksSync from '@/components/counterparty/QuickbooksSync.vue';
import  {
  VCombobox,
  VAutocomplete
} from 'vuetify/lib'

export default {
  components: {
    CounterpartyEdit,
    'v-combobox': VCombobox,
    'v-autocomplete': VAutocomplete,
    QuickbooksSync
  },
  props: {
    clearable: {
      type: Boolean,
      default: true
    },
    hideAdd: {
      type: Boolean,
      default: false
    },
    accountCompanyId: {
      type: Number,
    },
    quickbooks: {
      type: Boolean,
      default: false
    },
    currency: {
      type: String,
    },
    roles: {
      type: Array,
      required: true
    },
    value: {
      type: [Object, Array, null],
      default () {
        return this.multiple ? [] : null
      }
    },
    multiple: {
      type: Boolean,
      default() {
        return false;
      }
    },
    useSelect: {
      type: Boolean,
      default() {
        return false;
      }
    },
    qbType: {
      type: String,
      default: 'customer'
    },
    required: {
      type: Boolean,
      default: false,
    },
    suggested: {
      type: String,
      required: false
    },
    ignoreCheck: {
      type: Boolean,
      default: false
    }
  },
  name: "CounterpartySelect",
  data() {
    return {
      result: null,
      blacklisted: false,
      items: [],
      fakeItems: [],
      isLoading: false,
      search: this.suggested ? this.suggested : null,
      searchResult: null,
      showQB: false,
      isLinked: false,
      validationRules: [
        (value) => {
          if (!this.$props.required) {
            return true;
          }

          if (this.$props.useSelect) {
            return !!value?.length || 'This field is required';
          }

          return !!value || 'This field is required';
        },
      ],
    };
  },
  mounted () {
    if (this.value && !this.multiple) {
      this.items = [this.value]
    } else {
      this.items = this.value
    }
    if (this.quickbooks && this.value) {
      this.getLinked()
    }
  },
  computed: {
    label() {
      switch (this.$props.roles[0]) {
        case "customer":
          return "Customer";
        case  "dispatcher":
          return "Dispatcher";
        case  "sales_representant":
          return "Sales representants";
        case  "carrier":
          return "Name,company...";
        case  "consignee":
          return "Consignee";
        case "shipper":
          return "Shipper";
        case "customs_broker":
          return "Custom broker";
      }
    },
    appendIcon () {
      return this.value ? 'mdi-eye' : 'mdi-plus'
    }
  },
  methods: {
    getLinked() {
      axiosInstance.get(config.apiUrl + '/api/quickbooks/link-' + this.qbType, {
        params: {
        "accounting_company_id": this.accountCompanyId,
        "type": this.qbType === 'customer' ? "counterparty" : this.qbType,
        "local_identifier": this.value.id,
        "currency": this.currency
        }
      }).then((response) => {
        this.isLinked = true
      }).catch(() => {
        this.isLinked = false
      })
    },
    showQBModel (e) {
      this.showQB = true
      e.stopPropagation()
    },
    onInput (event, eventType) {
        if(event && event.hasOwnProperty('carrier_data') && event?.carrier_data?.is_blacklisted === 1 && !this.ignoreCheck) {
          this.$emit('input', null, eventType)
          if(eventType === 'change') {
            alert('This carrier is in the blacklist. Reason: ' + event.carrier_data.blacklist_notes);
          }
        } else if (event?.status === 'inactive' && !this.ignoreCheck) {
          this.$emit('input', null, eventType)
          if(eventType === 'change') {
            alert('This customer is inactive(has no load information for 90 days)')
          }
        } else {
          this.$emit('input', event || null, eventType)
          this.search = '';
          if (!this.multiple) {
            this.updateItems()
          }
          this.$nextTick(() => {
            this.items = JSON.parse(JSON.stringify(this.fakeItems))
          })
        }
    },
    updateItems() {
      axiosInstance.get(config.apiUrl + "/api/counterparty", {
        params: {
          role: this.$props.roles,
          search: this.search,
          page: "1",
          itemsPerPage: "20"
        }
      }).then(response => {
        this.items = response.data.data.filter((item) => {
          return !this.value || item.id !== this.value.id
        });
        this.fakeItems = JSON.parse(JSON.stringify(this.items));
        if (!this.multiple) {
          this.items.unshift(this.value)
        } else {
          this.value.forEach(item => {
            this.items.unshift(item)
          })
        }
      }).catch(err => {
        console.log(err);
      }).finally(() => (this.isLoading = false));
    },

    showMenu: function () {
      if (this.value) {
        if(this.value.id) {
          this.$store.dispatch('users/freshInEdit', this.value.id)
        } else if (this.value?.length === 1) this.$store.dispatch('users/freshInEdit', this.value[0].id)
      } else {
        this.$store.commit('users/newInEdit', { name: (this.suggested ? this.suggested : null), roles: this.$props.roles });
      }
    }
  },
  watch: {
    value (value) {
      if (this.value && !this.multiple) {
        this.items = [value]
        if (this.quickbooks) {
          this.getLinked()
        }
      } else {
        this.items = value
      }
    }
  }
};


</script>

<style scoped>

</style>
