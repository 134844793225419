<template>
  <div>
    <div v-if="loaded">
      <div v-if="loaded">
        <v-row align="stretch" dense justify="center">
          <v-col md="5">
            <v-card outlined>
              <v-card-text>
                <v-row>
                  <v-col md="6">
                    <GridCard :count="true" :name="'Revenue'" :data="data.revenue" />
                  </v-col>
                  <v-col md="6">
                    <GridCard :count="true" :name="'Gross Profit'" :data="data.gross" />
                  </v-col>
                  <v-col md="6">
                    <GridCard :count="true" :name="'Operating Profit'" :data="data.operating" />
                  </v-col>
                  <v-col md="6">
                    <GridCard :count="true" :name="'Net Profit'" :data="data.net" />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col md="3">
            <v-card outlined>
              <v-card-text>
                <radialBar :count="true" :height="175" :set-colors="['#3CB371']" :name="'Gross Profit Margin (%)'"
                  :data="data.gross_profit_margin" />
                <hr>
                <radialBar :count="true" :height="175" :set-colors="['#3CB371']" :name="'Operating Profit Margin (%)'"
                  :data="data.operating_profit_margin" />
                <hr>
                <radialBar :count="true" :height="175" :set-colors="['#3CB371']" :name="'Net Profit Margin (%)'"
                  :data="data.net_profit_margin" />
              </v-card-text>
            </v-card>
          </v-col>
          <v-col md="4">
            <ColumnChart :set-legend="false" :height="297" :count="true" :name="'Income Statement'" :data="data.income_statement" />
          </v-col>
        </v-row>
      </div>

      <div v-if="loaded">
        <v-row align="stretch">
          <v-col md="8">
            <v-row dense>
              <v-col md="6">
                <v-card outlined>
                  <v-card-text>
                    <SimpleCounter :name="'Days Receivable Outstanding'" :count="data.days_receivable_outstanding"
                      :subject-count="385" :type="'invoices'" />
                    <hr>
                    <strong>Receivable Aging</strong>
                    <v-simple-table>
                      <tr>
                        <td>Total Receivable</td>
                        <td>{{ data.receivable.total }}</td>
                      </tr>
                      <tr>
                        <td>Overdue Receivable</td>
                        <td>{{ data.receivable.overdue }}</td>
                      </tr>
                      <tr>
                        <td>% of Overdue Receivable</td>
                        <td :class="data.receivable.percent > 0 ? 'text-danger' : 'text-success'">{{
                          data.receivable.percent.toFixed(2) }}%</td>
                      </tr>
                    </v-simple-table>
                    <hr>
                    <ColumnChart :set-datalabels="true" :set-legend="true" :height="170" :count="true"
                      :data="data.receivable_chart"
                      :set-colors="['rgb(60,179,113)', 'rgba(60,179,113,0.7)', 'rgba(220,20,60,0.5)', 'rgba(220,20,60,1)']" />

                  </v-card-text>
                </v-card>
              </v-col>
              <v-col md="6">
                <v-card outlined>
                  <v-card-text>
                    <SimpleCounter :name="'Days Payable Outstanding'" :count="data.days_payable_outstanding"
                      :subject-count="755" :type="'bills'" />
                    <hr>
                    <strong>Payable Aging</strong>
                    <v-simple-table>
                      <tr>
                        <td>Total Payable</td>
                        <td>{{ data.payable.total }}</td>
                      </tr>
                      <tr>
                        <td>Overdue Receivable</td>
                        <td>{{ data.payable.overdue }}</td>
                      </tr>
                      <tr>
                        <td>% of Overdue Receivable</td>
                        <td :class="data.payable.percent > 0 ? 'text-danger' : 'text-success'">{{
                          data.payable.percent.toFixed(2) }}%</td>
                      </tr>
                    </v-simple-table>
                    <hr>
                    <ColumnChart :set-datalabels="true" :set-legend="true" :height="170" :count="true"
                      :data="data.payable_chart"
                      :set-colors="['rgb(60,179,113)', 'rgba(60,179,113,0.7)', 'rgba(220,20,60,0.5)', 'rgba(220,20,60,1)']" />

                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <v-col md="4">
            <v-row dense>
              <v-col md="12">
                <SimpleGrid :count="true" :name="'Net Operating Cash Flow'" :data="data.net_operating_cash_flow" />
              </v-col>
              <v-col class="d-none" md="12">
                <SimpleGrid :count="true" :name="'Net Financing Cash Flow'" :data="data.net_financing_cash_flow" />
              </v-col>
              <v-col class="d-none" md="12">
                <SimpleGrid :count="true" :name="'Net Investing Cash Flow'" :data="data.net_investing_cash_flow" />
              </v-col>
              <v-col md="12">
                <SimpleGrid :count="true" :name="'Cash at End of Month'" :data="data.cash_end_month" />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>

      <div v-if="loaded">
        <v-row>
          <v-col md="8">
            <v-row dense>
              <v-col md="4">
                <LineChart :set-datalabels="true" :set-legend="true" :height="240" :count="true"
                  :data="data.current_ratio" :name="'Current Ratio'" />
              </v-col>
              <v-col md="4">
                <LineChart :set-datalabels="true" :set-legend="true" :height="240" :count="true" :data="data.quick_ratio"
                  :name="'Quick Ratio'" />
              </v-col>
              <v-col md="4">
                <LineChart :set-datalabels="true" :set-legend="true" :height="240" :count="true" :data="data.quick_ratio"
                  :name="'Debt: Equity Ratio'" />
              </v-col>
            </v-row>
          </v-col>
          <v-col md="4" class="px-1">
            <v-card outlined>
              <v-card-text>
                <strong>Balance Sheet</strong>
                <hr>
                <v-simple-table dense>
                  <template v-slot:default>
                    <tbody>
                      <tr>
                        <td>Fixed Assets</td>
                        <td>{{ balance.fixedAssets }}</td>
                      </tr>
                      <tr>
                        <td>Current Assets</td>
                        <td>{{ balance.currentAssets }}</td>
                      </tr>
                      <tr>
                        <td>Other Assets</td>
                        <td>{{ balance.otherAssets }}</td>
                      </tr>
                    </tbody>
                    <tfoot class="font-weight-bold">
                      <tr>
                        <td>Total Assets</td>
                        <td>{{ totalAssets }}</td>
                      </tr>
                    </tfoot>
                  </template>
                </v-simple-table>
                <hr>
                <v-simple-table dense class="w-100">
                  <template v-slot:default>
                    <tbody>
                      <tr>
                        <td>Current Liabilities</td>
                        <td>{{ balance.currentLiabilities }}</td>
                      </tr>
                      <tr>
                        <td>Long Term Liabilities</td>
                        <td>{{ balance.longTermLiabilities }}</td>
                      </tr>
                      <tr>
                        <td>Equity</td>
                        <td>{{ balance.equity }}</td>
                      </tr>
                    </tbody>
                    <tfoot class="font-weight-bold">
                      <tr>
                        <td>Total Liabilities & Equity</td>
                        <td>{{ totalLiabilitiesAndEquity }}</td>
                      </tr>
                    </tfoot>
                  </template>
                </v-simple-table>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>

      <div v-if="loaded">
        <div class="row">
          <div class="col">
            <ColumnChart :set-datalabels="true" :set-legend="true" :height="297" :count="true" :data="taxesChart" :name="'Taxes'" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import GridCard from './widgets/GridCard.vue';
import radialBar from './widgets/RadialBar.vue';
import ColumnChart from './widgets/ColumnChart.vue';
import SimpleGrid from './widgets/SimpleGrid.vue';
import SimpleCounter from './widgets/SimpleCounter.vue';
import LineChart from './widgets/LineChart.vue';
import axios from "@/plugins/axios";
import config from "@/config";
export default {
  name: "DashboardCFO",
  components: {
    apexchart: VueApexCharts,
    GridCard,
    radialBar,
    ColumnChart,
    SimpleGrid,
    SimpleCounter,
    LineChart
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    filters: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loaded: false,
      balance: {
        "fixedAssets": 0,
        "currentAssets": 0,
        "otherAssets": 0,
        "currentLiabilities": 0,
        "longTermLiabilities": 0,
        "equity": 0
      },
      taxesChart: {
        series: [
          {
            data: [
              {
                x: '< 30 days',
                y: 540,
              },
              {
                x: '< 60 days',
                y: 405,
              },
              {
                x: '< 90 days',
                y: 270,
              },
              {
                x: '> 90 days',
                y: 135,
              }
            ]
          }
        ],
      }
    }
  },
  mounted() {
    this.data.revenue = {
      count: 0,
      last: 0,
      target: 0,
    }
    this.data.gross = {
      count: 0,
      last: 0,
      target: 0,
    }
    this.data.receivable_chart.series[0].data = [];
    this.data.payable_chart.series[0].data = [];

    this.loaded = false;
    this.fetchApiData();
  },
  computed: {
    totalAssets () {
      return this.balance.currentAssets + this.balance.fixedAssets + this.balance.otherAssets;
    },
    totalLiabilitiesAndEquity () {
      return this.balance.currentLiabilities + this.balance.longTermLiabilities + this.balance.equity;
    }
  },
  methods: {
    fetchApiData() {
      this.$emit('loading', true)
      axios.get(config.apiUrl + '/api/reports/cfo-totals', {
        params: this.filters
      }).then((response) => {
        this.data.revenue = {
          count: response.data.revenue['curr'].toFixed(),
          last: response.data.revenue['prev'] ?? '-',
          target: 0,
        }
        this.data.gross = {
          count: response.data.grossProfit['curr'].toFixed(),
          last: response.data.grossProfit['prev'] ?? '-',
          target: 0,
        }
        this.data.operating = {
          count: response.data.operatingProfit['curr'].toFixed(),
          last: response.data.operatingProfit['prev'] ?? '-',
          target: 0,
        }
        this.data.net = {
          count: response.data.netProfit['curr'].toFixed(),
          last: response.data.netProfit['prev'] ?? '-',
          target: 0,
        }
        this.data.gross_profit_margin = {
          series: [((response.data.grossProfit['curr'] / response.data.revenue['curr']) * 100).toFixed(2)],
          target: 0,
          last: (((response.data.grossProfit['prev'] ?? 0) / (response.data.revenue['prev'] ?? 1)) * 100).toFixed(2)
        }
        this.data.operating_profit_margin = {
          series: [((response.data.operatingProfit['curr'] / response.data.revenue['curr']) * 100).toFixed(2)],
          target: 0,
          last: (((response.data.operatingProfit['prev'] ?? 0) / (response.data.revenue['prev'] ?? 1)) * 100).toFixed(2)
        }
        this.data.net_profit_margin = {
          series: [((response.data.netProfit['curr'] / response.data.revenue['curr']) * 100).toFixed(2)],
          target: 0,
          last: (((response.data.netProfit['prev'] ?? 0) / (response.data.revenue['prev'] ?? 1)) * 100).toFixed(2)
        }
        this.data.receivable_chart.series[0].data = response.data.daysReceivable.map(item => {
          return {
            x: item['DIFF'],
            y: item['COUNT'],
          }
        })
        this.data.payable_chart.series[0].data = response.data.daysPayable.map(item => {
          return {
            x: item['DIFF'],
            y: item['COUNT'],
          }
        })
        this.data.income_statement.series[0].data[0] = parseInt(response.data.revenue['curr'].toFixed())
        this.data.income_statement.series[0].data[1] = parseInt(response.data.costs['curr'].toFixed())
        this.data.income_statement.series[0].data[2] = parseInt(response.data.otherOperatingExpenses['curr'].toFixed())
        this.data.income_statement.series[0].data[3] = parseInt(response.data.nonOperatingIncome['curr'].toFixed())
        this.data.income_statement.series[0].data[4] = parseInt(response.data.financialExpenses['curr'].toFixed())
        this.data.income_statement.series[0].data[5] = parseInt(response.data.taxes['curr'].toFixed())
        this.data.income_statement.series[0].data[6] = parseInt(response.data.netProfit['curr'].toFixed())

        this.data.days_receivable_outstanding = response.data?.daysReceivableAvg.toFixed(2)
        this.data.days_payable_outstanding = response.data?.daysPayableAvg.toFixed(2)
        this.data.receivable = {
          total: response.data.totalReceivable,
          overdue: response.data.totalOverdueReceivable,
          percent: (response.data.totalReceivable / response.data.totalOverdueReceivable) * 100
        }
        this.data.payable = {
          total: response.data.totalPayable,
          overdue: response.data.totalOverduePayable,
          percent: ( response.data.totalOverduePayable / response.data.totalPayable) * 100
        }

        this.data.net_operating_cash_flow = {
          count: Math.round(response.data.netOperatingCashFlow)
        }

        this.data.current_ratio.series[0]['data'] = response.data.currentRatio.map((row) => {
          return {
            x: row.MONTH,
            y: row.RATIO,
          }
        })

        let avgCurrentRatio = 0;
        response.data.currentRatio.forEach(i => {
          avgCurrentRatio += i.RATIO
        })
        avgCurrentRatio = avgCurrentRatio / response.data.currentRatio.length
        this.data.current_ratio.count = avgCurrentRatio.toFixed(1)


        this.data.quick_ratio.series[0]['data'] = response.data.quickRatio.map((row) => {
          return {
            x: row.MONTH,
            y: row.RATIO,
          }
        })

        let avgQuickRatio = 0;
        response.data.quickRatio.forEach(i => {
          avgQuickRatio += i.RATIO
        })
        avgQuickRatio = avgQuickRatio / response.data.quickRatio.length
        this.data.quick_ratio.count = avgQuickRatio.toFixed(1)

        this.balance = response.data.balance

        this.data.net_operating_cash_flow.count = parseInt(response.data.netOperatingCashFlow.toFixed())

        this.taxesChart.series[0]['data'] = response.data.taxesByMonth.map(item => {
          return {
            x: item['MONTH'],
            y: parseInt(item['VALUE']),
          }
        })

        this.$emit('loading', false)
        this.loaded = true
      })
    }

  }
}
</script>
