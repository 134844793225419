<template>
  <v-container fluid>
    <v-navigation-drawer width="370" temporary absolute v-model="filters">
      <v-list-item>
        <strong>Filter loads:</strong>
      </v-list-item>
      <v-list-item class="mb-4">
        <v-text-field v-model="filtersData.search" outlined append-icon="mdi-magnify" label="Quick search" dense clearable
          hide-details></v-text-field>
      </v-list-item>
      <v-list-item class="mb-4">
        <LoadStatusSelect v-model="filtersData.status" multiple></LoadStatusSelect>
      </v-list-item>
      <v-list-item v-if="false">
        <v-autocomplete v-model="filtersData.customer_id" :items="items" dense chips outlined small-chips label="Customer"
          multiple></v-autocomplete>
      </v-list-item>
      <v-list-item v-if="false">
        <v-autocomplete :items="items" dense chips outlined small-chips label="Carrier or Driver" multiple>
        </v-autocomplete>
      </v-list-item>
      <v-list-item v-if="false">
        <v-autocomplete :items="items" dense chips outlined small-chips label="Consignee" multiple>
        </v-autocomplete>
      </v-list-item>
      <v-list-item class="mb-5" v-if="this.$store.getters.role.includes('admin')">
        <CounterpartySelect @input="filtersData.dispatcher_id = $event ? $event.id : null" :roles="['dispatcher']"
          label="Dispatcher"></CounterpartySelect>
      </v-list-item>
      <v-list-item class="mb-5" v-if="this.$store.getters.role.includes('admin')">
        <CounterpartySelect @input="filtersData.sales_representant_id = $event ? $event.id : null"
          :roles="['sales_representant']" label="Sales representant"></CounterpartySelect>
      </v-list-item>
      <v-list-item v-if="this.$store.getters.role.includes('admin')">
        <v-autocomplete v-model="filtersData.team_id" :items="teams" item-value="id" item-text="name" dense chips outlined
          small-chips label="By team" clearable multiple>
        </v-autocomplete>
      </v-list-item>
      <v-list-item>
        <v-menu :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field :value="shipDatesRangeText" placeholder="Ship date" append-icon="mdi-calendar" outlined dense
              readonly v-bind="attrs" v-on="on" clearable @click:clear="filtersData.shipDates = []"></v-text-field>
          </template>
          <v-date-picker range v-model="filtersData.shipDates"></v-date-picker>
        </v-menu>
      </v-list-item>
      <v-list-item>
        <v-menu :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field :value="deliveryDatesRangeText" placeholder="Delivery date" append-icon="mdi-calendar" outlined
              dense readonly v-bind="attrs" v-on="on" clearable
              @click:clear="filtersData.deliveryDates = []"></v-text-field>
          </template>
          <v-date-picker range v-model="filtersData.deliveryDates"></v-date-picker>
        </v-menu>
      </v-list-item>
      <v-list-item>
        <v-btn @click="getLoads" color="primary" class="mr-3">SEARCH</v-btn>
        <v-btn @click="resetFilters" color="info">RESET</v-btn>
      </v-list-item>
    </v-navigation-drawer>
    <div class="d-flex ">
      <!--
      <v-breadcrumbs class="pt-0 pl-0 pb-0" :items="breads" divider="/"></v-breadcrumbs> -->
    </div>
    <div class="page-header px-3 py-1">
      <v-row class="align-items-center">
        <v-col md="auto" class="header-bg">
          <div class="d-flex align-items-center">
            <h4 class="m-0 mr-5">Loads management</h4>
            <v-btn color="secondary" small class="mr-3 px-9" @click="openLoad">+ New load</v-btn>
            <v-btn color="light" small class="mr-3" @click.stop="filters = !filters"><v-icon
                small>mdi-filter-cog-outline</v-icon></v-btn>
              <v-btn color="cyan lighten-5"  small class="mr-3 px-9" disabled>
                <v-icon small>
                  mdi-map-marker-multiple-outline
                </v-icon>
              </v-btn>
          </div>
        </v-col>
        <v-col>
          <v-row class="align-items-center">
            <v-col md="4" class="ml-auto">
              <v-text-field @keydown.enter="getLoads"  clearable v-model="filtersData.search"
                append-icon="mdi-magnify" label="Search" @click:clear="clearSearch" dense  hide-details="auto">
              </v-text-field>
            </v-col>
            <v-col style="max-width: 150px">
              <v-select :items="[{ value: true, label: 'Team data' }, { value: false, label: 'My data' }]"
                v-model="filtersData.show_team_loads" @change="getLoads" item-value="value" item-text="label" hide-details
                dense solo flat>
              </v-select>
            </v-col>
            <v-col style="max-width: 400px">
              <v-tabs class="mini-tabs" v-model="loadsStatusTab" right height="30">
                <v-tab>Open loads</v-tab>
                <v-tab>Delivered/Completed</v-tab>
              </v-tabs>
            </v-col>
          </v-row>
          <div class="d-flex justify-content-end">
          </div>
        </v-col>
        <v-col class="d-none">
          <div class="d-flex align-items-center">
            <div class="d-none">
              <v-spacer></v-spacer>
              <v-btn small color="light" class="mr-5">
                <v-icon small class="mr-1">
                  mdi-file
                </v-icon>
                Export .pdf
              </v-btn>
              <v-btn small color="light" class="mr-5">
                <v-icon small class="mr-1">
                  mdi-table
                </v-icon>
                Export .xls
              </v-btn>
              <v-btn small color="secondary">
                <v-icon small class="mr-1">
                  mdi-printer
                </v-icon>
                Print
              </v-btn>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
    <v-data-table :options="defaultOptions" ref="loadsTable" :headers="headers" :items="loads.data"
      @click:row="handleClick($event)" class="loads-table custom-table table-bordered mt-3" :loading="loading"
      item-key="id" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" @update:options="onOptionUpdate"
      :page.sync="loads.meta.current_page" :server-items-length="loads.meta.total" :custom-sort="items => items" dense
      :footer-props="{
        'items-per-page-options': [50, 100, 300]
      }">
      <template v-slot:item.id="{ item }">
        <div class="d-flex align-items-center justify-space-between">
          <strong style="font-weight: bold;">#{{ item.id }}</strong>
          <div @click.stop="openComments(item.id)">
            <v-badge v-if="item.comments_count > 0" :content="item.comments_count" color="green" overlap>
              <v-icon color="grey lighten-1">mdi-comment-outline</v-icon>
            </v-badge>
            <v-icon v-else color="grey lighten-1">mdi-comment-outline</v-icon>
          </div>
        </div>
      </template>
      <template v-slot:item.wo="{ item }">
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <span v-if="item.wo && item.wo.length > 20" class="link mini" v-bind="attrs" v-on="on">
              {{ item.wo ? item.wo.substring(0, 20) + " ..." : '-' }}
            </span>
            <span v-else-if="item.wo === null" class="link mini">
              -
            </span>
            <span v-else class="link mini">
              {{ item.wo ? item.wo : '-' }}
            </span>
          </template>
          <span>{{ item.wo }}</span>
        </v-tooltip>
      </template>
      <template v-slot:item.date_ship="{ item }">
        <div v-if="item.load_carriers.length">
          <div v-if="item.load_carriers[0].load_carrier_shippers.length >= 2">
            <span class="link mini">
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    {{ formatDate(item.load_carriers[0].load_carrier_shippers[0].date) }} {{
                      formatTime(item.load_carriers[0].load_carrier_shippers[0].time) }} <v-icon class="ml-3"
                      small>mdi-information-outline</v-icon>
                  </span>
                </template>
                <li v-for="dates  in  item.load_carriers[0].load_carrier_shippers">{{ formatDate(dates.date) }} {{
                  formatTime(dates.time) }}</li>
              </v-tooltip>
            </span>
          </div>
          <div v-else>
            <span style="white-space: nowrap;" class="link mini"
              v-for="dates  in  item.load_carriers[0].load_carrier_shippers">
              {{ formatDate(dates.date) }} <span class="time">{{ dates.time ? ' - ' + formatTime(dates.time) : null
              }}</span>
            </span>
          </div>
        </div>
        <div v-else>
          -
        </div>
      </template>
      <template v-slot:item.date_del="{ item }">
        <div v-if="item.load_carriers.length">
          <div
            v-if="item.load_carriers[0].load_carrier_consignees.length >= 2 && item.load_carriers[0].load_carrier_consignees[0]">
            <span class="link mini">
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    {{ formatDate(item.load_carriers[0].load_carrier_consignees[0].date) }} {{
                      formatTime(item.load_carriers[0].load_carrier_consignees[0].time) }} <v-icon class="ml-3"
                      small>mdi-information-outline</v-icon>
                  </span>
                </template>
                <li v-for="dates  in  item.load_carriers[0].load_carrier_consignees">{{ formatDate(dates.date) }} {{
                  formatTime(dates.time) }}</li>
              </v-tooltip>
            </span>
          </div>
          <div v-else>
            <span style="white-space: nowrap;" class="link mini"
              v-for="dates  in  item.load_carriers.at(-1).load_carrier_consignees">
              {{ formatDate(dates.date) }} <span class="time">{{ dates.time ? ' - ' + formatTime(dates.time) : null
              }}</span>
            </span>
          </div>
        </div>
        <div v-else>
          -
        </div>
      </template>
      <template v-slot:item.carrier="{ item }">

        <v-icon
            v-if="hasTrackingIcon(item)"
            color="gray"
            small
            class="mr-2"
        >
          {{ getLoadChatIcon(item.id) }}
        </v-icon>

        <template class="link" v-if="item.load_carriers[0].carrier &&  item.load_carriers.length === 1">
          <strong>{{ item.load_carriers[0]?.carrier?.name }}</strong>
        </template>
        <template class="link" v-else-if="item.load_carriers.length > 1">

          <span class="mini" style="color: green">Multiple carriers</span>
        </template>
        <div class="mini" style="color: red;" v-else>
          Carrier not assigned
        </div>
      </template>

      <template v-slot:item.customer="{ item }">
        <strong>{{ item.load_finances.customer ? item.load_finances.customer.name : '-' }}</strong>
      </template>

      <!-- LOCATIONS -->
      <template v-slot:item.origin="{ item }">
        <div class="load-address" v-if="item.load_carriers.length">
          <div v-if="item.load_carriers[0].load_carrier_shippers.length >= 2">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <template v-if="item.load_carriers[0] && item.load_carriers[0].load_carrier_shippers[0]">
                  <h3 v-bind="attrs" v-on="on">{{
                    formattedAddress(item.load_carriers[0].load_carrier_shippers[0] &&
                      item.load_carriers[0].load_carrier_shippers[0])
                  }} <v-icon class="ml-3" small>mdi-information-outline</v-icon></h3>
                  <span class="table-company">{{
                    item.load_carriers[0] &&
                    item.load_carriers[0].load_carrier_shippers[0] &&
                    item.load_carriers[0].load_carrier_shippers[0].shipper ?
                    item.load_carriers[0].load_carrier_shippers[0].shipper.name : '-'
                  }}</span>
                </template>

              </template>
              <ul>
                <li v-for="shipper in item.load_carriers[0].load_carrier_shippers">
                  <strong>{{ formattedAddress(shipper) }}</strong> <br>
                  <span>{{ shipper.shipper ? shipper.shipper.name : '-' }}</span>
                </li>
              </ul>
            </v-tooltip>
          </div>
          <div v-else>
            <h3 v-if="item.load_carriers[0] && item.load_carriers[0].load_carrier_shippers[0]">{{
              formattedAddress(item.load_carriers[0] && item.load_carriers[0].load_carrier_shippers[0])
            }}</h3>
            <span class="table-company">{{
              item.load_carriers[0] && item.load_carriers[0].load_carrier_shippers[0] &&
              item.load_carriers[0].load_carrier_shippers[0].shipper ?
              item.load_carriers[0].load_carrier_shippers[0].shipper.name : ''
            }}</span>
          </div>
        </div>
        <div v-else>
          -
        </div>
      </template>
      <template v-slot:item.direction="{ item }">
        <v-icon>
          mdi-chevron-right
        </v-icon>
      </template>
      <template v-slot:item.destination="{ item }">
        <div class="load-address" v-if="item.load_carriers.length">
          <div v-if="item.load_carriers[0].load_carrier_consignees.length >= 2">

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <h3 v-bind="attrs" v-on="on">{{
                  item.load_carriers[0] &&
                  item.load_carriers[0].load_carrier_consignees[0] ?

                  formattedAddress(
                    item.load_carriers.at(-1).load_carrier_consignees.at(-1)
                  )

                  : '-'
                }} <v-icon class="ml-3" small>mdi-information-outline</v-icon></h3>
                <span class="table-company">{{
                  item.load_carriers.at(-1) &&
                  item.load_carriers.at(-1).load_carrier_consignees.at(-1) &&
                  item.load_carriers.at(-1).load_carrier_consignees.at(-1).consignee ?
                  item.load_carriers.at(-1).load_carrier_consignees.at(-1).consignee.name : '-'
                }}</span>

              </template>
              <ul>
                <li v-for="consignee in item.load_carriers[0].load_carrier_consignees">
                  <strong>{{ consignee.location }}</strong> <br>
                  <span>{{ consignee.name ?? "" }}</span>
                </li>
              </ul>
            </v-tooltip>
          </div>
          <div v-else>

            <h3 v-if="item.load_carriers.at(-1) && item.load_carriers.at(-1).load_carrier_consignees.at(-1)">
              {{
                formattedAddress(

                  item.load_carriers.at(-1).load_carrier_consignees.at(-1)
                )
              }}
            </h3>
            <span class="table-company">{{
              item.load_carriers.at(-1) && item.load_carriers.at(-1).load_carrier_consignees.at(-1)
              && item.load_carriers.at(-1).load_carrier_consignees.at(-1).consignee ?
              item.load_carriers.at(-1).load_carrier_consignees.at(-1).consignee.name : '-'
            }}</span>

          </div>

        </div>
        <div v-else>
          -
        </div>
      </template>

      <template v-slot:item.status="{ item }">
        <v-menu bottom :offset-x="true">
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="full-box" dark :color="statusColors[item.status]" v-bind="attrs" v-on="!['invoiced', 'completed'].includes(item.status) ? on : null"
              :loading="loadingStatus === item.id">
              <span v-if="item.status === 'refused'"><strike>{{ humanize(item.status).toUpperCase() }}</strike></span>
              <span v-else>{{ humanize(item.status).toUpperCase() }}</span>

            </v-btn>
          </template>
          <v-list dense>
            <v-list-item v-for="(status, index) in loadStatuses" :key="index" @click="updateStatus(item, status)">
              <v-list-item-title>{{ humanize(status) }}</v-list-item-title>
            </v-list-item>
            <v-list-item style="color:red" @click="loadToDelete = item.id">
              <v-list-item-title>Delete</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <template v-slot:item.action="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)">
          mdi-attachment
        </v-icon>
        <v-icon small class="mr-2" @click="deleteItem(item)">
          mdi-note-text-outline
        </v-icon>
        <v-icon small @click="editItem(item)">
          mdi-dots-horizontal
        </v-icon>
      </template>
    </v-data-table>
    <v-pagination class="my-3" v-model="loads.meta.current_page" :length="loads.meta.last_page"
      :total-visible="10"></v-pagination>


    <Load v-if="$store.state.loadInEdit" @unlocked="updateLoads" @saved="getLoads" />
    <CounterpartyEdit v-if="$store.state.users.inEdit" :counterparty.sync="$store.state.users.inEdit"></CounterpartyEdit>
    <v-dialog :value="loadComments" max-width="800" @click:outside="loadComments = null">
      <v-card>
        <v-card-text>
          <Comments :load-id="loadComments" @addedComment="getLoads" />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog :value="loadToDelete" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5">
          Remove load?
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" dark @click="loadToDelete = null">
            No
          </v-btn>
          <v-btn color="red darken-1" dark @click="deleteLoad(loadToDelete)">
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <FilesModal v-if="loadInFileManager" :files="files" @close="files = false" :additionalData="additionalData" :load="loadInFileManager" :restrictedEdit="restrictedEdit"></FilesModal>
  </v-container>
</template>

<script>

import axiosInstance from "@/plugins/axios";
import CounterpartySelect from "@/components/counterparty/CounterpartySelect";
import config from "@/config";
import Load from "@/components/layout/Load";
import * as dayjs from 'dayjs'
import CounterpartyEdit from "@/components/counterparty/CounterpartyEdit";
import loadStatuses from "@/helpers/loadStatuses";
import FilesModal from "@/components/files/Modal.vue";
import humanize from "@/helpers/humanize"
import axios from "@/plugins/axios";
import LoadStatusSelect from "@/components/loads/LoadStatusSelect";
import Comments from "@/components/loads/Comments.vue";
import { EventBus } from "@/helpers/eventBus";
import loadLayoverMixin from '@/mixins/loadLayoverMixin';

export default {
  name: "Loads",
  components: {
    LoadStatusSelect,
    CounterpartyEdit,
    Load,
    CounterpartySelect,
    FilesModal,
    Comments
  },
  mixins: [loadLayoverMixin],
  data() {
    return {
      sheet: false,
      loadComments: null,
      loadToDelete: null,
      loading: false,
      loadingStatus: null,
      statusColors: {
        'open': 'red darken-2',
        'covered': 'light-blue darken-3',
        'dispatched': 'brown darken-1',
        'loading': 'blue-grey darken-1',
        'on_route': 'teal accent-3',
        'unloading': 'teal accent-4',
        'in_yard': 'teal darken-2',
        'delivered': 'green accent-4',
        'completed': 'blue-grey darken-4',
        'refused': 'deep-orange accent-3',
        'claim': 'deep-orange accent-3',
      },
      apiParams: null,
      filters: null,
      filtersData: {
        search: null,
        team_id: [],
        dispatcher_id: null,
        sales_representant_id: null,
        shipDates: [],
        deliveryDates: [],
        status: [
          'open',
          'covered',
          'dispatched',
          'loading',
          'on_route',
          'unloading',
          'in_yard',
          'refused',
          'claim'
        ],
        withInvoice: false,
        show_team_loads: false
      },
      sortBy: null,
      sortDesc: true,
      currency: null,
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      dates: [],
      loadInFileManager: null,
      files: false,
      formatting: [],
      toggle_exclusive: null,
      breads: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/',
        },
        {
          text: 'Loads',
          disabled: true,
        },
      ],
      selectedItem: 0,
      items: [
        { text: 'Dashboard', icon: 'mdi-chart-pie' },
        { text: 'Loads management', icon: 'mdi-table' },
        { text: 'Counterparties', icon: 'mdi-domain' },
        { text: 'Financial', icon: 'mdi-file-chart-check-outline' },
      ],
      search: null,
      headers: [
        {
          text: 'Load #',
          align: 'start',
          sortable: true,
          width: 100,
          value: 'id',
        },
        { text: 'Status', value: 'status' },
        { text: 'W/O #', value: 'wo' },
        { text: 'Driver/Carrier', value: 'carrier' },
        { text: 'Ship date', value: 'date_ship' },
        { text: 'Delivery date', value: 'date_del' },
        { text: 'Customer', value: 'customer' },
        { text: 'Origin', value: 'origin', sortable: false },
        { text: '', value: 'direction', sortable: false },
        { text: 'Destination', value: 'destination', sortable: false },
        //{ text: 'Action', value: 'action' }
      ],
      loads: {
        data: [],
        meta: {
          total: null
        }
      },
      teams: [],
      loadsStatusTab: 0,
      page: 1,
      loadsWithUnreadChatMessages: [],
      tokenForChat: null
    }
  },
  computed: {
    additionalData() {
      if (!this.loadInFileManager) return {}
      return {
        load_file_type: this.loadInFileManager.load_carriers.length === 1 ? 'single' : 'multiple'
      }
    },
    restrictedEdit() {
      //if(!this.loadInFileManager) return false
      //if ((this.$store.getters.role.includes('admin') || (this.$store.getters.role.includes('customer_support')) && this.loadInFileManager?.status === 'invoiced') || ((this.$store.getters.role.includes('dispatcher') || this.$store.getters.role.includes('sales_representant') && (this.loadInFileManager?.status === 'invoiced' || this.loadInFileManager?.status === 'completed')))) {
        //return false
      //}
      //return this.restrictLoadEdit(this.loadInFileManager)
    },
    shipDatesRangeText() {
      return this.filtersData.shipDates.join(' ~ ')
    },
    deliveryDatesRangeText() {
      return this.filtersData.deliveryDates.join(' ~ ')
    },
    loadStatuses() {
      return loadStatuses
    },
    defaultOptions() {
      return localStorage.getItem('loadTableOptions') ? JSON.parse(localStorage.getItem('loadTableOptions')) : {}
    }
  },
  created() {
    if (localStorage.getItem('dashboardLoadFilters')) {
      this.filtersData = JSON.parse(localStorage.getItem('dashboardLoadFilters'))
    }
    if (localStorage.getItem('dashboardLoadsStatusTab')) {
      this.loadsStatusTab = parseInt(localStorage.getItem('dashboardLoadsStatusTab'))
    }
  },
  destroyed() {
    this.stopFetchingUnreadMessages()
  },
  mounted() {

    this.tokenForChat = localStorage.getItem('tokenForChat')

    // Logout user, tokenForChat will be generated at login
    if (!this.tokenForChat) {
      this.$router.push('/logout')
    }

    this.startFetchingUnreadMessages()

    EventBus.$on('update:carrier-data', (val) => {
      this.$store.state.users.inEdit = val
    })
    axios.get(config.apiUrl + '/api/team')
      .then(response => {
        this.teams = response.data
      })
  },
  methods: {
    hasTrackingIcon(item) {
      if (
          item.load_carriers.filter(lc => lc.status === 'in_progress').length > 0 &&
          item.load_carrier_tokens.length > 0
      ) {
        return true
      }
    },
    startFetchingUnreadMessages() {
      if (!this.tokenForChat) {
        return
      }
      this.getUnreadChatMessages()
      this.fetchInterval = window.setInterval(() => {
        this.getUnreadChatMessages()
      }, 5000)
    },
    stopFetchingUnreadMessages() {
      clearInterval(this.fetchInterval)
    },
    getUnreadChatMessages() {
      fetch(config.chatWebSocketUrl + "/recover-rooms", {
        headers: {
          Authorization: "Bearer " + this.tokenForChat,
        },
      }).then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }
        return response.json()
      }).then(data => {
        this.loadsWithUnreadChatMessages = data
      }).catch(error => {
        console.error('Error:', error)
      })
    },
    getLoadChatIcon(loadId) {
      const hasUnreadMessages = this.loadsWithUnreadChatMessages.some(
          obj => obj.external_id === String(loadId) && obj.unread_messages_count > 0
      )
      return hasUnreadMessages ? 'mdi-map-marker' : 'mdi-map-marker-outline'
    },
    openComments(value) {
      this.loadComments = value
    },
    clearSearch() {
      this.filtersData.search = null
      this.getLoads()
    },
    deleteLoad(id) {
      axios.delete(config.apiUrl + '/api/load/' + id)
        .then(() => {
          this.getLoads()
          this.loadToDelete = null
        })
    },
    updateLoads(data) {
      if (data.refresh) {
        this.getLoads()
        this.$store.state.loadInEdit = this.loads.data.find(el => el.id === data.id)
      }
    },
    onOptionUpdate(options) {
      localStorage.setItem('loadTableOptions', JSON.stringify(options))
      this.apiParams = options
      this.getLoads()
    },
    humanize(str) {
      return humanize.humanize(str)
    },
    updateStatus(load, status) {
      this.loadingStatus = load.id
      axios.patch(config.apiUrl + '/api/load/' + load.id + '/attribute', {
        attribute: 'status',
        value: status
      }).then(() => {
        load.status = status
        this.loadingStatus = false
        //if(load.status === 'completed' || load.status === 'invoiced') {
          //this.loadInFileManager = load
          //this.files = true
        //}
      }).catch((error) => {
        alert(`Update status error:\n\n${error.response.data.errors.join('\r')}`)
        this.loadingStatus = false
      })
    },
    handleClick(item) {
      this.$store.commit('openLoad', item)

      this.$nextTick(() => {
        this.$socket.emit('lock', {
          id: item.id,
          type: 'load',
          name: this.$store.state.auth.user.name
        })
      })
    },
    openLoad() {
      this.$store.commit('createLoad')
    },
    getLoads() {
      this.loading = true
      axiosInstance.get(config.apiUrl + '/api/load', {
        params: Object.assign(
          {},
          this.apiParams,
          this.filtersData,
          { shipDates: JSON.parse(JSON.stringify(this.filtersData.shipDates)).sort() },
          { deliveryDates: JSON.parse(JSON.stringify(this.filtersData.deliveryDates)).sort() }
        )
      })
        .then(response => {
          this.loads = response.data
          this.loading = false
        })
    },
    formatDate(value) {
      return value ? dayjs(value).format('MM/DD/YYYY') : 'Not set'
    },
    formatTime(value) {
      return value ? dayjs('1/1/1 ' + value).format('hh:mm A') : ''
    },
    formattedAddress(item) {
      if (!item.location_parts) {
        return item.location
      }
      const parts = [
        item.location_parts.city,
        item.location_parts.state,
        item.location_parts.country_code,
      ]

      return parts.filter(i => !!i).join(', ')
    },
    resetFilters() {
      for (const prop in this.filtersData) {
        if (Array.isArray(this.filtersData[prop])) {
          this.filtersData[prop] = []
        } else {
          this.filtersData[prop] = null
        }
      }
      this.getLoads()
    }
  },
  watch: {
    loadsStatusTab(value) {
      localStorage.setItem('dashboardLoadsStatusTab', value)
      if (value === 0) {
        this.filtersData.status = [
          'open',
          'covered',
          'dispatched',
          'loading',
          'on_route',
          'unloading',
          'in_yard',
          'refused',
          'claim'
        ];
        this.filtersData.withInvoice = false;
      } else {
        this.filtersData.status = [
          'completed',
          'delivered',
          'invoiced'
        ];
        this.filtersData.withInvoice = null;
      }
      this.getLoads()
    },
    filtersData: {
      handler(filters) {
        localStorage.setItem('dashboardLoadFilters', JSON.stringify(filters))
      },
      deep: true
    }
  }
};
</script>

<style>
.show-offices label.v-label {
  margin-bottom: 0;
}

.v-breadcrumbs {
  padding: 0 !important;
}
</style>
