import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"drop-zone"},[(_vm.files !== null)?_c(VRow,_vm._l((_vm.fields.data),function(item,idx){return _c(VCol,{key:'vdz-' + idx,attrs:{"md":_vm.fields.options.col}},[_c('vue-dropzone',{ref:"myVueDropzone",refInFor:true,attrs:{"id":'dropzone-' + idx,"options":_vm.dropzoneOptions,"useCustomSlot":true,"clickable":!item.uploaded},on:{"vdropzone-file-added":function($event){return _vm.setStatus(idx)},"vdropzone-upload-progress":function($event){_vm.loading = true},"vdropzone-complete":function($event){return _vm.uploadCompleted($event, item.type)},"vdropzone-sending":(file, xhr, formData) => _vm.sendingEvent(file, xhr, formData, item.type)}},[_c('div',{class:item.required ? 'drop-item text-center mandatory' : 'drop-item text-center',style:(item.uploaded ? 'border-color: green; border-style: solid;' : '')},[_c('h5',[_vm._v(_vm._s(item.name))]),(item.uploaded)?_c(VIcon,{attrs:{"color":"green lighten-1"}},[_vm._v("mdi-check-circle")]):_c(VIcon,{attrs:{"color":"grey lighten-1"}},[_vm._v("mdi-cloud-upload")]),(item.uploaded && typeof item.files[0] !== 'undefined')?[_c(VBtn,{staticClass:"mt-1",attrs:{"text":"","x-small":""},on:{"click":function($event){$event.stopPropagation();return _vm.downloadFile(item.files[0])}}},[_vm._v("Download file")]),_c(VBtn,{staticClass:"mt-1",attrs:{"text":"","x-small":"","color":"red"},on:{"click":function($event){$event.stopPropagation();return _vm.removeFile(item.files[0])}}},[_c(VIcon,{attrs:{"x-small":""}},[_vm._v("mdi-close")])],1)]:_vm._e()],2)])],1)}),1):_c(VSkeletonLoader,{staticClass:"mx-auto",attrs:{"max-width":"100%","type":"card"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }