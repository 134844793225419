<template>
  <v-container fluid>
    <v-breadcrumbs class="pt-0 pl-0 pb-0" :items="breads" divider="/"></v-breadcrumbs>

    <v-row class="align-items-center">
      <v-col>
        <v-tabs class="mini-tabs mb-3" v-model="tabsModel" background-color="transparent">
          <v-tab :transition="false">
            Delivered
          </v-tab>
          <v-tab :transition="false">
            Completed
          </v-tab>
          <v-tab :transition="false">
            Invoiced
          </v-tab>
          <v-tab :transition="false">
            Paid
          </v-tab>

        </v-tabs>
      </v-col>
      <v-col md="auto">
        <v-btn v-if="selectedLoads.length > 0" @click="invoiceQuickEdit = true" small class="mb-3"  color="secondary">bulk edit</v-btn>
      </v-col>
      <v-col md="8">
        <v-row class="mt-1">
          <v-col>
            <v-menu v-if="[0,1].includes(tabsModel)" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field :value="shipDatesRangeText" placeholder="Ship date" append-icon="mdi-calendar" outlined dense readonly v-bind="attrs" v-on="on" clearable @click:clear="shipDates = []"></v-text-field>
              </template>
              <v-date-picker range v-model="shipDates"></v-date-picker>
            </v-menu>
          </v-col>
          <v-col>
            <v-menu v-if="[0,1].includes(tabsModel)" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field :value="deliveryDatesRangeText" placeholder="Delivery date" append-icon="mdi-calendar" outlined dense readonly v-bind="attrs" v-on="on" clearable @click:clear="deliveryDates = []"></v-text-field>
              </template>
              <v-date-picker range v-model="deliveryDates"></v-date-picker>
            </v-menu>
            <v-menu v-if="[2,3].includes(tabsModel)" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field :value="invoiceDatesRangeText" placeholder="Invoice date" append-icon="mdi-calendar" outlined dense readonly v-bind="attrs" v-on="on" clearable @click:clear="invoiceDates = []"></v-text-field>
              </template>
              <v-date-picker range v-model="invoiceDates"></v-date-picker>
            </v-menu>
          </v-col>
          <v-col>
            <v-text-field
                @keydown.enter="onGlobalSearch"
                label="Search..."
                append-icon="mdi-magnify"
                outlined clearable dense hide-details
                @click:clear="globalSearch = null"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-combobox
                clearable
                outlined
                multiple
                label="Columns"
                dense
                @focus="handleColumnsFocus(columns)"
                @blur="handleColumnsBlur(columns)"
                :items="columns"
                v-model="selectedCols"
                return-object
              >
              <template #selection="{index}">{{ getSelectionText(index) }}</template>
            </v-combobox>
          </v-col>
          <v-col>
            <v-combobox
                outlined
                label="Format"
                dense
                :items="exportFormats"
                v-model="exportFormat"
                return-object
              />
          </v-col>
          <v-col style="max-width: 130px">
            <v-btn @click="doExport">
              EXPORT
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-tabs-items v-model="tabsModel">
      <v-tab-item :transition="false">
        <Loads @toTab="tabsModel = 1" v-if="tabsModel === 0" :params="{ status: ['delivered'], sortDesc: ['true'], sortBy: ['id'], search: globalSearch, shipDates: shipDates, deliveryDates: deliveryDates }" />
      </v-tab-item>
      <v-tab-item :transition="false">
        <Loads @loads-selected="onLoadsSelected" :show-select="true" v-if="tabsModel === 1" :params="{ status: ['completed'], sortDesc: ['true'], sortBy: ['id'], search: globalSearch, shipDates: shipDates, deliveryDates: deliveryDates, with: ['loadFinances.customer.customerData.paymentTerm'] }"  />
      </v-tab-item>
      <v-tab-item :transition="false">
        <InvoicesTable v-if="tabsModel === 2" :params="{ status: ['unpaid'], sortDesc: ['true'], sortBy: ['id'], search: globalSearch, invoiceDates: invoiceDates }" />
      </v-tab-item>
      <v-tab-item :transition="false">
        <InvoicesTable v-if="tabsModel === 3" :params="{ status: ['paid', 'partially_paid'], sortDesc: ['true'], sortBy: ['id'], search: globalSearch, invoiceDates: invoiceDates }"  />
      </v-tab-item>
    </v-tabs-items>
    <InvoiceBulkEdit v-if="invoiceQuickEdit" :is-active="invoiceQuickEdit" :loads="selectedLoads" @close="closeInvoiceQuickEdit"></InvoiceBulkEdit>
  </v-container>
</template>

<script>
import Load from "@/components/layout/Load";
import CounterpartySelect from "@/components/counterparty/CounterpartySelect";
import LoadStatusSelect from "@/components/loads/LoadStatusSelect";
import InvoiceEdit from "./InvoiceEdit.vue";
import InvoicesTable from "./table/Invoices.vue";
import Loads from "./table/Loads.vue";
import InvoiceBulkEdit from "@/components/invoice/InvoiceBulkEdit.vue";
import { EventBus } from "@/helpers/eventBus";
export default {
  components: {
    InvoiceBulkEdit,
    Load,
    CounterpartySelect,
    LoadStatusSelect,
    InvoiceEdit,
    InvoicesTable,
    Loads
  },
  data() {
    return {
      tabsModel: 0,
      globalSearch: null,
      shipDates: [],
      deliveryDates: [],
      invoiceDates: [],
      selectedLoads: [],
      selectedCols: [],
      invoiceQuickEdit: false,
      exportFormat: {
        text: "CSV (.csv)",
        value: "csv"
      },
      exportFormats: [
        {text: "CSV (.csv)", value: "csv"},
        {text: "XLSX (.xlsx)", value: "xlsx"}
      ],
      selectedCols: []
    }
  },
  mounted() {
    if (this.$store.getters.role.includes('dispatcher')) {
      this.$router.push({ name: '404' })
    }
  },
  computed: {
    breads() {
      return [
        {
          text: 'FREIGHTLINKTRACKER',
          disabled: false,
          href: '/',
        },
        {
          text: 'Accounting manager',
          disabled: true,
        },
      ]
    },
    columns() {
      if(this.tabsModel == 0 || this.tabsModel == 1) {
        return [
          { text: 'Load #', align: 'start', sortable: true, value: 'id' },
          { text: 'Ship date', value: 'date_ship', sortable: true, },
          { text: 'Delivery date', value: 'date_delivery', sortable: true, },
          { text: 'Customer', value: 'customer', sortable: true, },
          { text: 'Driver/Carrier', value: 'carrier', sortable: false, },
          { text: 'Dispatcher', value: 'dispatcher.name', sortable: false, },
        ]
      } else if(this.tabsModel == 2 || this.tabsModel == 3) {
        return [
          { text: 'Load #', align: 'start',  sortable: true,  value: 'load' },
          { text: 'Invoice #', align: 'start', sortable: true, value: 'id' },
          { text: 'Status', value: 'status', sortable: false, },
          { text: 'Invoice date', value: 'date', sortable: true, },
          { text: 'Customer', value: 'customer', sortable: true, },
          { text: 'Amount', value: 'totalDue', sortable: true, },
        ]
      } else return []
    },
    shipDatesRangeText() {
      return this.shipDates.join(' ~ ')
    },
    deliveryDatesRangeText() {
      return this.deliveryDates.join(' ~ ')
    },
    invoiceDatesRangeText() {
      return this.invoiceDates.join(' ~ ')
    },
  },
  methods: {
    doExport() {
      let params = {
        format: this.exportFormat.value
      }
      if(this.selectedCols.length !== 0 && this.selectedCols.length !== this.columns.length) {
        params.excluded_columns = this.columns
          .filter(el => !this.selectedCols.map(el => el.value)
          .includes(el.value))
          .map(el => el.value)
      }

      if (this.tabsModel === 0 || this.tabsModel === 1) {
        EventBus.$emit('exportAccountingLoads', params, this.tabsModel)
      } else if (this.tabsModel === 2 || this.tabsModel === 3) {
        EventBus.$emit('exportAccountingInvoices', params, this.tabsModel)
      }

    },
    getSelectionText(index) {
      if (index === 0) {
        if (this.selectedCols.length > 1) {
          return this.selectedCols[0].text + '...'
        } else {
          return this.selectedCols[0].text
        }
      } else return ''
    },
    handleColumnsFocus() {
      if (this.selectedCols.length === 0) {
        this.selectedCols = this.columns
      }
    },
    handleColumnsBlur() {
      if (this.selectedCols.length === this.columns.length) {
        this.selectedCols = []
      }
    },
    onGlobalSearch (event) {
      this.globalSearch = event.target.value
    },
    onLoadsSelected (loads) {
      this.$nextTick(() => {
        this.selectedLoads = loads
      })
    },
    closeInvoiceQuickEdit () {
      this.invoiceQuickEdit = false
      this.selectedLoads = this.selectedLoads
    }
  },
  watch: {
    tabsModel () {
      this.selectedLoads = []
      this.selectedCols = []
    }
  }
}
</script>
