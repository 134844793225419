<template>

  <v-container fluid>

    <v-row justify="center">
      <v-col cols="12" md="6">

        <div class="header">
          <div class="logo" style="margin: 20px auto; width: max-content;">
            <v-btn normal text color="white">
              <span>FREIGHTLINKTRACKER</span>
            </v-btn>
          </div>
        </div>

        <div v-if="isFormSubmitted" class="text-center my-5 py-5">

          <v-icon size="64" class="text-success mb-5">mdi-check-circle-outline</v-icon>
          <h3 class="text-success">Thanks for submitting the form!</h3>

        </div>

        <v-form v-else ref="newCarrierForm" v-model="isFormValid">
          <v-card>

            <v-card-title class="primary white--text py-1 d-flex justify-center">
              <h2 class="text-center">Become a Carrier</h2>
            </v-card-title>

            <v-card-text>

              <div class="general_info mt-4">
                <h3 class="ms-5">General Info:</h3>

                <v-text-field
                    outlined dense clearable
                    label="CARRIER NAME"
                    v-model="formData.name"
                    :rules="[validationRules.required]"
                >
                </v-text-field>

                <v-text-field
                    outlined dense clearable
                    label="CONTACT NAME"
                    v-model="formData.counterparty_contacts[0].contact_person"
                    :rules="[validationRules.validName]"
                >
                </v-text-field>

                <LocationAutocomplete
                    :countries="allowedCountries"
                    :must-have-post-code="true"
                    :hide-input-errors="false"
                    label="BILLING ADDRESS"
                    :id="'cae-' + 1"
                    :entity="{location: ''}"
                    @selected="updateAddress(addressBilling, ...arguments)"
                    :validation-rules="[validationRules.validAddress]"
                ></LocationAutocomplete>

                <v-text-field
                    outlined dense clearable
                    label="PHONE NUMBER"
                    v-model="formData.counterparty_contacts[0].contact"
                    maxlength="10"
                    :rules="[validationRules.validPhone]"
                >
                </v-text-field>

                <v-text-field
                    outlined dense clearable
                    label="BILLING EMAIL"
                    v-model="formData.counterparty_contacts[1].contact"
                    :rules="[validationRules.validEmail]"
                >

                </v-text-field>

                <v-row v-if="!localInCanada">
                  <v-col>
                    <v-text-field
                        outlined dense clearable
                        label="MC#"
                        v-model="formData.carrier_data.mc_ff_value"
                        maxlength="7"
                        :rules="[validationRules.validMc]"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                        outlined dense clearable
                        label="DOT#"
                        v-model="formData.carrier_data.dot"
                        maxlength="8"
                        :rules="[validationRules.validDot]"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>

                <v-sheet class="text-center">
                  <v-btn color="primary" class="w-50" @click="handleLocalInCanada">
                    <span v-text="localInCanada ? 'NOT LOCAL IN CANADA?' : 'LOCAL IN CANADA?'"></span>
                  </v-btn>
                </v-sheet>

                <v-text-field
                    v-if="localInCanada"
                    outlined dense clearable
                    v-model="formData.carrier_data.cbi_value"
                    class="mt-4"
                    label="BUSINESS ID# (CANADA CARRIERS ONLY*)"
                    :rules="[validationRules.required]"

                >
                </v-text-field>

              </div>

              <div class="authority mt-4">
                <h3 class="ms-5">Authority:</h3>

                <v-row>
                  <v-col>
                    <v-text-field
                        outlined dense clearable
                        label="REGISTRATION#"
                        v-model="formData.carrier_data.docs.authority.registration_number"
                        :rules="[validationRules.required]"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col>

                    <v-text-field
                        type="date"
                        onfocus="this.showPicker()"
                        outlined dense
                        label="EXPIRY DATE"
                        v-model="formData.carrier_data.docs.authority.expiry_date"
                        :rules="[validationRules.required]"
                    >
                    </v-text-field>

                  </v-col>
                </v-row>

                <v-file-input
                    :accept="acceptedExtensions"
                    v-model="files.mc_nir_cvor"
                    outlined label="AUTHORITY FILE"
                    prepend-icon=""
                    append-icon="mdi-paperclip"
                    :rules="[validationRules.required]"
                >
                </v-file-input>
              </div>

              <div class="payment mt-4">
                <h3 class="ms-5">Payment info:</h3>

                <v-row>
                  <v-col>
                    <v-autocomplete
                        :items="factoringCompanies"
                        class="mini"
                        v-model="formData.carrier_data.factoring_company_id"
                        item-text="name"
                        item-value="id"
                        label="Factoring company"
                        persistent-hint outlined dense clearable
                    >
                    </v-autocomplete>
                    <v-text-field
                        outlined dense clearable
                        label="BANK NAME"
                        v-model="formData.counterparty_bank_account.name"
                        :rules="[validationRules.required]"
                    >
                    </v-text-field>
                    <v-text-field
                        outlined dense clearable
                        label="INSTITUTION#"
                        v-model="formData.counterparty_bank_account.institution_number"
                        :rules="[validationRules.required, validationRules.validBankInstitutionNumber]"
                        maxlength="4"
                    >
                    </v-text-field>
                    <v-text-field
                        outlined dense clearable
                        label="ACCOUNT#"
                        v-model="formData.counterparty_bank_account.account_number"
                        :rules="[validationRules.required, validationRules.validBankAccountNumber]"
                        maxlength="9"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                        v-if="!localInCanada"
                        outlined dense clearable
                        label="ABA# (US CARRIERS ONLY)"
                        v-model="formData.carrier_data.aba_number"
                        :rules="[validationRules.required, validationRules.validAbaNumber]"
                        maxlength="9"
                    >
                    </v-text-field>
                    <v-text-field
                        type="date"
                        onfocus="this.showPicker()"
                        outlined dense
                        label="DATE"
                        v-model="formData.counterparty_bank_account.registration_date"
                        :rules="[validationRules.required]"
                    >
                    </v-text-field>
                    <v-text-field
                        outlined dense clearable
                        label="TRANSIT#"
                        v-model="formData.counterparty_bank_account.transit_number"
                        :rules="[validationRules.required, validationRules.validBankTransitNumber]"
                        maxlength="5"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <CounterpartySignature
                    @signed="generateSignatureFile($event)"
                    width="450px"
                    height="208px"
                    @clear="files.signature = null"
                    :validate-signature="validateSignature"
                />
                <v-file-input
                    :accept="acceptedExtensions"
                    v-model="files.bank_details"
                    outlined label="PAYMENT FILE"
                    prepend-icon=""
                    append-icon="mdi-paperclip"
                    :rules="[validationRules.required]"
                >
                </v-file-input>
              </div>

              <div class="insurance mt-4">
                <h3 class="ms-5">Insurance:</h3>

                <v-row>

                  <v-col>
                    <v-text-field
                        outlined dense clearable
                        label="AUTO LIABILITY"
                        v-model="formData.counterparty_insurances[0].policy_number"
                        :rules="[validationRules.required]"
                    >
                    </v-text-field>
                    <v-text-field
                        outlined dense clearable
                        label="CARGO LIABILITY"
                        v-model="formData.counterparty_insurances[1].policy_number"
                        :rules="[validationRules.required]"
                    >
                    </v-text-field>
                    <v-text-field
                        outlined dense clearable
                        label="GENERAL LIABILITY"
                        v-model="formData.counterparty_insurances[2].policy_number"
                        :rules="[validationRules.required]"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col>
                    <v-text-field
                        type="date"
                        onfocus="this.showPicker()"
                        outlined dense
                        label="DATE"
                        v-model="formData.counterparty_insurances[0].policy_expiration"
                        :rules="[validationRules.required]"
                    >
                    </v-text-field>
                    <v-text-field
                        type="date"
                        onfocus="this.showPicker()"
                        outlined dense
                        label="DATE"
                        v-model="formData.counterparty_insurances[1].policy_expiration"
                        :rules="[validationRules.required]"
                    >
                    </v-text-field>
                    <v-text-field
                        type="date"
                        onfocus="this.showPicker()"
                        outlined dense
                        label="DATE"
                        v-model="formData.counterparty_insurances[2].policy_expiration"
                        :rules="[validationRules.required]"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>

                <v-file-input
                    :accept="acceptedExtensions"
                    v-model="files.insurance_certificate"
                    outlined label="INSURANCE FILE"
                    prepend-icon=""
                    append-icon="mdi-paperclip"
                    :rules="[validationRules.required]"
                >
                </v-file-input>
              </div>

              <div class="w89_form mt-4">
                <h3 class="ms-5">W8/9 FORM</h3>

                <v-row>
                  <v-col>

                    <LocationAutocomplete
                        :must-have-post-code="true"
                        :hide-input-errors="false"
                        label="BILLING ADDRESS"
                        :id="'cae-' + 2"
                        :entity="addressEntityW89"
                        @selected="updateAddress(addressW89, ...arguments)"
                        :validation-rules="[validationRules.validAddress]"
                    ></LocationAutocomplete>

                  </v-col>
                  <v-col>
                    <v-text-field
                        type="date"
                        onfocus="this.showPicker()"
                        outlined dense
                        label="EXPIRY DATE"
                        v-model="formData.carrier_data.docs.w8_9_form.expiry_date"
                        :rules="[validationRules.required]"
                    >
                    </v-text-field>

                  </v-col>

                </v-row>

                <v-text-field
                    outlined dense clearable
                    label="EMPLOYER IDENTIFICATION#"
                    v-model="formData.carrier_data.docs.w8_9_form.employer_identification"
                    :rules="[validationRules.required]"
                >
                </v-text-field>

                <v-file-input
                    :accept="acceptedExtensions"
                    v-model="files.w8_9"
                    outlined label="W 8/9 FILE"
                    prepend-icon=""
                    append-icon="mdi-paperclip"
                    :rules="[validationRules.required]"
                >
                </v-file-input>
              </div>

              <div class="my-5 d-flex align-items-center justify-content-center">
                <v-checkbox v-model="isTermsAgreed" :rules="[validationRules.required]">
                  <template v-slot:label>
                    <div>
                      I have read and agreed to the
                      <v-tooltip>
                        <template v-slot:activator="{ props }">
                          <a href="/Terms-and-Conditions.pdf" target="_blank" v-bind="props" @click.stop>
                            Terms and Conditions
                          </a>
                        </template>
                      </v-tooltip>
                    </div>
                  </template>
                </v-checkbox>
              </div>

              <div class="d-flex justify-content-center mb-3">
                <v-btn :loading="isLoading" @click="addNewCarrier" x-large color="primary" class="w-50">
                  SUBMIT
                </v-btn>
              </div>

            </v-card-text>

          </v-card>
        </v-form>

      </v-col>
    </v-row>

    <v-snackbar
        v-model="carrierNewError"
        :timeout="5000"
        color="red accent-2"
    >
      <b>Errors:</b>
      <div v-html="carrierNewErrorText"></div>
    </v-snackbar>

  </v-container>

</template>

<script>

import axios from "axios";
import config from "@/config";
import LocationAutocomplete from "@/components/location/LocationAutocomplete.vue";
import CounterpartySignature from "@/components/counterparty/CounterpartySignature.vue";

export default {
  name: "CarrierNew",
  components: {CounterpartySignature, LocationAutocomplete},
  data() {
    return {
      allowedCountries: ['ca', 'us'],
      acceptedExtensions: '.pdf, .doc, .docx, .jpg, .jpeg, .png',
      agreementFile: null,
      isLoading: false,
      carrierNewErrorText: null,
      carrierNewError: false,
      validateSignature: false,
      isSigned: false,
      factoringCompanies: [],
      isFormSubmitted: false,
      isFormValid: false,
      addressEntityW89: {
        location: ''
      },
      addressBilling: {},
      addressW89: {},
      validationRules: {
        required: value => !!value || 'Required',
        validAddress: value => !!value || 'Please provide and address that has postal code',
        validName: value => (value && /^[a-z ,.'-]+$/i.test(value)) || 'Invalid name',
        validEmail: value => (value && /^[\w-\.]+@([\w-]+\.)+[\w-]{2,24}$/.test(value)) || 'E-mail must be valid',
        validPhone: value => (value && (value.length === 10 && Number.isInteger(Number(value)))) || 'Phone number must contain 10 digits',
        validMc: value => (value && (value.length >= 5 && value.length <= 7) && Number.isInteger(Number(value))) || 'Must be between 5 and 7 digits',
        validDot: value => (value && (value.length >= 6 && value.length <= 8) && Number.isInteger(Number(value))) || 'Must be between 6 and 8 digits',
        validBankInstitutionNumber: value => (value && value.length === 4 && Number.isInteger(Number(value))) || 'Must contain 4 digits',
        validBankAccountNumber: value => (value && (value.length === 7 || value.length === 9) && Number.isInteger(Number(value))) || 'Must contain 7 (USA) or 9 digits (Canada)',
        validBankTransitNumber: value => (value && value.length === 5 && Number.isInteger(Number(value))) || 'Must contain 5 digits',
        validAbaNumber: value => (value && value.length === 9 && Number.isInteger(Number(value))) || 'Must contain 9 digits',

      },
      localInCanada: false,
      formData: {
        roles: [
          "carrier"
        ],
        counterparty_contacts: [
          {
            type: "phone"
          },
          {
            type: "email"
          },
        ],
        counterparty_addresses: [],
        counterparty_insurances: [
          {
            type: "auto",
            contact_name: null,
          },
          {
            type: "cargo",
            contact_name: null,
          },
          {
            type: "liability",
            contact_name: null,
          },
        ],
        counterparty_bank_account: {},
        carrier_data: {
          mc_ff_type: 'mc',
          docs: {
            authority: {},
            w8_9_form: {},
          }
        },
      },
      isTermsAgreed: false,
      files: {
        mc_nir_cvor: null,
        bank_details: null,
        insurance_certificate: null,
        w8_9: null,
        signature: null,
      }
    }
  },
  mounted() {
    axios.get(config.apiUrl + '/api/counterparty/factoring-companies').then((response) => {
      this.factoringCompanies = response.data
    })

    fetch('/Terms-and-Conditions.pdf')
        .then(response => response.blob())
        .then(blob => {
          this.agreementFile = new File([blob], 'Terms-and-Conditions.pdf', {type: 'application/pdf'})
        })
        .catch(error => {
          console.error('Error loading PDF file:', error);
        })
  },
  methods: {
    handleLocalInCanada() {
      this.localInCanada = !this.localInCanada
      if (this.localInCanada) {
        this.allowedCountries = ['ca']
        this.formData.carrier_data.mc_ff_type = 'cbi'
        this.formData.carrier_data.mc_ff_value = this.formData.carrier_data.cbi_value
        this.formData.carrier_data.dot = null
      } else {
        this.allowedCountries = ['ca', 'us']
      }
    },
    addNewCarrier() {
      if (!this.isSigned) {
        this.validateSignature = true
        return
      }
      if (!this.$refs.newCarrierForm.validate()) {
        return
      }
      this.isLoading = true
      axios.post(config.apiUrl + '/api/counterparty/carrier-new', this.formData)
          .then((response) => {
            const carrierId = response.data.id
            return this.uploadFiles(carrierId).then(() => {
              return response.data.carrier_data.uuid
            })
          })
          .then((uuid) => {
            return axios.post(
                config.apiUrl + '/api/counterparty/carrier-send-mail/' + uuid
            ).catch((error) => {
              console.log(error)
              this.isLoading = false
            })
          })
          .then(() => {
            this.isFormSubmitted = true
            this.isLoading = false
          })
          .catch((error) => {
            const errors = error.response.data.errors
            this.carrierNewErrorText = Object.values(errors).flatMap(errorList => errorList).join('<br>')
            this.carrierNewError = true
            this.isLoading = false
          })
    },
    async generateSignatureFile(data) {
      const base64Response = await fetch(data)
      const blob = await base64Response.blob()
      this.files.signature = new File([blob], 'Signature.png', {type: 'image/png'})
      this.isSigned = true
    },
    getFilesFormDatas(carrierId) {
      const filesFormDatas = {}
      for (const file_type in this.files) {
        let formData = new FormData()
        formData.append('file_type', file_type)
        formData.append('files[]', this.files[file_type])
        formData.append('entity_id', carrierId)
        formData.append('entity_type', 'counterparty')

        filesFormDatas[file_type] = formData
      }
      return filesFormDatas
    },
    uploadFiles(carrierId) {
      const uploadPromises = []
      for (const formData of Object.values(this.getFilesFormDatas(carrierId))) {
        uploadPromises.push(
            axios.post(config.apiUrl + '/api/file/upload', formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            })
        )
      }

      // Send Agreement and signature
      let formDataAgreement = new FormData()
      formDataAgreement.append('entity_id', carrierId)
      formDataAgreement.append('signature', this.files.signature)
      formDataAgreement.append('agreement', this.agreementFile)
      uploadPromises.push(
          axios.post(config.apiUrl + '/api/counterparty/carrier-new-agreement/', formDataAgreement, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
      )

      return Promise.all(uploadPromises)
    },
    updateAddress(addressObject, addressData, components) {
      let addrNumber = ''
      let addrStreet = ''
      components['address_components'].forEach((item) => {
        if (item.types.includes('street_number')) {
          addrNumber = item['long_name']
        } else if (item.types.includes('route')) {
          addrStreet = item['long_name']
        } else if (item.types.includes('locality')) {
          addressObject.city = item['long_name']
        } else if (item.types.includes('administrative_area_level_1')) {
          addressObject.state = item['short_name'].toUpperCase()
        } else if (item.types.includes('country')) {
          addressObject.country_code = item['short_name'].toUpperCase()
        } else if (item.types.includes('postal_code')) {
          addressObject.postcode = item['short_name'].toUpperCase()
        }
      })

      addressObject.address1 = (addrNumber ? (addrNumber + ' ') : '') + addrStreet
      addressObject.location_coordinates = {
        type: 'Point',
        coordinates: [
          addressData.longitude,
          addressData.latitude,
        ]
      }

      delete addressObject.photos

      addressObject.location_data = components

      if (Object.keys(this.addressW89).length === 0) {
        this.addressEntityW89.location = components['formatted_address']
        this.addressW89 = { ...this.addressBilling }
      }
      this.formData.counterparty_addresses = [this.addressBilling, this.addressW89];
    }
  }
}

</script>
