<template>
  <div>
    <div v-if="loaded">
      <v-row dense>
        <v-col>
          <IconGridCard :count="true" :name="'Sales'" :icon="'mdi-account-cash-outline'" :data="data.status.sales" />
        </v-col>
        <v-col>
          <IconGridCard :count="true" :name="'Accounts Payable'" :icon="'mdi-handshake-outline'" :data="data.status.receivable" />
        </v-col>
        <v-col>
          <IconGridCard :count="true" :name="'Overdue Payable'" :icon="'mdi-timer-alert-outline'" :data="data.status.overdue" />
        </v-col>
        <v-col>
          <IconGridCard :count="true" :name="'% of Overdue Payable'" :icon="'mdi-percent-outline'" :data="data.status.percent" />
        </v-col>
        <v-col>
          <IconGridCard :count="true" :name="'Net Operating Cash Flow'" :icon="'mdi-cash-multiple'" :data="data.status.net" />
        </v-col>
      </v-row>
    </div>
    <div v-if="loaded">
      <v-row dense>
        <v-col md="12">
          <LineChartColumn :count="false" :height="550" :data="data.sales"
            :set-colors="['#3CB371', '#000', '#DC143C']" />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import IconGridCard from './widgets/IconGridCard.vue';
import ColumnChart from './widgets/ColumnChart.vue';
import BarNegative from './widgets/BarNegative.vue'
import BarBasic from './widgets/BarBasic.vue';
import SimpleCounter from './widgets/SimpleCounter.vue';
import LineChartColumn from './widgets/LineChartColumn.vue';
import config from "@/config";
import axios from "@/plugins/axios";
export default {
  name: "DashboardPayable",
  components: {
    apexchart: VueApexCharts,
    IconGridCard,
    ColumnChart,
    BarNegative,
    BarBasic,
    SimpleCounter,
    LineChartColumn
},
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loaded: false
    }
  },
  mounted() {
    this.$emit('loading', true)
    axios.get(config.apiUrl + '/api/reports/account-payable-totals').then((response) => {
      this.data.sales = response.data.sales

      this.data.sales.count = response.data.totalSales
      this.data.status.sales.count = response.data.totalSales
      this.data.status.receivable.count = response.data.totalAccountReceivable
      this.data.status.overdue.count = response.data.totalOverdueReceivable
      this.data.status.percent.count = response.data.percentOverdueReceivable
      this.data.status.net.count = parseInt(response.data.netOperatingCashFlow.toFixed());

      this.loaded = true

      this.$emit('loading', false)
    })
  },
  methods: {
  }
}
</script>
