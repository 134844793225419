<template>
  <v-row justify="center">
    <v-dialog @click:outside="$store.state.users.inEdit = null" :value="true" max-width="1000px" overlay-opacity="0.2"
      transition="0" @keydown.esc="$store.state.users.inEdit = null">
      <v-card class="mini-form" min-height="650">
        <v-toolbar dark dense :color="$store.state.users.inEdit.status == 'active' ? 'dark' : 'red'">
          <v-btn icon dark @click="$store.state.users.inEdit = null">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title v-if="formData">
            {{ typeof formData.name ? formData.name : 'Counterparty edit' }} {{ $store.state.users.inEdit.status == 'inactive' ? '(inactive profile)' : '' }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items class="align-items-center">
            <v-btn v-if="isCarrier && formData.counterparty_saferwatch" class="ml-3" small light @click="checkSW">
              Safer Watch
              <div class="ml-3 d-flex align-items-center">
                <span class="text-default font-weight-bold subtitle-1">S</span>
                <span class="text-success font-weight-bold subtitle-1">W</span>
                <v-icon color="success" small>mdi-check-circle</v-icon>
              </div>
            </v-btn>
            <v-btn v-if="certificateExists" small color="darken-3" dark @click="downloadCertificate">
              <v-icon left light>
                mdi-file-certificate-outline
              </v-icon>
              Certificate
            </v-btn>
            <v-btn small color="light-blue darken-3" dark v-if="formData.id">
              <v-icon left light>
                mdi-printer
              </v-icon>
              Print
            </v-btn>
            <v-btn small color="grey darken-1" dark @click="files = true" v-if="formData.id">
              <v-icon left light>
                mdi-attachment
              </v-icon>
              Files
            </v-btn>
            <v-btn color="red" @click="$store.state.users.inEdit = null">
              Close
            </v-btn>
            <v-btn v-if="false" class="px-10" color="secondary" :disabled="saveInProgress" @click="save(true)">
              Save and continue
            </v-btn>
            <v-btn style="margin-right:-18px" class="px-10" color="success" :disabled="saveInProgress"
              @click="save(false)">
              Save
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-form v-if="formData" ref="form">
          <v-dialog v-model="importSaferWatch" width="auto">
            <v-card flat dense>
              <v-card-title>Import from SaferWatch</v-card-title>
              <v-card-text>
                <div class="importSafer" v-if="isCarrier">
                  <v-text-field hide-details single-line :placeholder="'MC, DOT Number'" v-model="swId"
                    v-on:keyup.enter="importCarrier"></v-text-field>
                  <v-btn color="success" :loading="importCarrierInProgress" :disabled="importCarrierInProgress"
                    @click="importCarrier">Get</v-btn>
                </div>
              </v-card-text>
            </v-card>
          </v-dialog>
          <v-card-text>

            <v-tabs background-color="secondary" center-active dark v-model="counterpartyTabs">
              <v-tab>General</v-tab>
              <v-tab>Contacts</v-tab>
              <v-tab v-if="isCarrier">Insurance</v-tab>
              <v-tab>Settings</v-tab>
              <v-spacer></v-spacer>
              <v-btn text v-if="isCarrier"
                @click="$store.dispatch('carrierHistory/carrierHistory', { carrier_id: formData.id, per_page: 1000 })">
                <v-icon>mdi-history</v-icon> Carrier History</v-btn>
              <v-btn text v-if="isCustomer"
                  @click="$store.dispatch('customerHistory/customerHistory', { customer_id: formData.id, per_page: 1000 })">
                <v-icon>mdi-history</v-icon> Customer History
              </v-btn>
            </v-tabs>

            <v-tabs-items class="pt-5" v-model="counterpartyTabs" >
              <v-tab-item :transition="false">
                <v-row>
                  <v-col md="6">

                    <v-select class="mini" v-if="isDispatcher || isSales" v-model="formData.teams" :items="teams"
                      :menu-props="{ maxHeight: '400' }" item-text="name" label="Teams" return-object multiple dense
                      outlined></v-select>
                    <v-text-field v-if="haveCredentials" v-model="formData.user.email" label="E-mail" class="mini"
                      required outlined dense></v-text-field>
                    <v-row>
                      <v-col>
                        <v-text-field class="mini" v-if="haveCredentials" v-model="formData.user.password" :rules="[]"
                          label="Password" required outlined dense></v-text-field>
                      </v-col>
                      <v-col>
                        <v-text-field class="mini" v-if="haveCredentials" v-model="formData.user.password_confirmation"
                          :rules="[]" label="Password confirmation" required outlined dense></v-text-field>
                      </v-col>
                    </v-row>
                    <div>
                      <v-row>
                        <v-col md="6" class="pt-0">
                          <v-text-field class="mini" v-model="formData.name" :rules="[]" label="Name" required outlined
                            dense></v-text-field>

                        </v-col>
                        <v-col v-if="isCarrier || isCustomsBroker" md="2" class="pt-0">
                          <v-btn small block color="primary" class="d-flex" @click="swDialog = !swDialog">
                            <span class="text-default font-weight-bold subtitle-1">S</span>
                            <span class="text-success font-weight-bold subtitle-1">W</span>
                          </v-btn>
                        </v-col>
                        <v-col md="4" class="pt-0">
                          <v-btn block small outlined @click="createAccount = !createAccount">
                            Account
                          </v-btn>
                        </v-col>
                      </v-row>
                    </div>
                    <div>
                      <v-card v-if="createAccount" outlined>
                        <v-card-text>
                          <v-row>
                            <v-col md="12">
                              <v-text-field v-model="formData.user.email" :rules="validation.emailRules" label="E-mail"
                                class="mini" required outlined dense></v-text-field>
                              <v-row>
                                <v-col>
                                  <v-text-field class="mini" v-model="formData.user.password" :rules="[]" label="Password"
                                    required outlined dense></v-text-field>
                                </v-col>
                                <v-col>
                                  <v-text-field class="mini" v-model="formData.user.password_confirmation" :rules="[]"
                                    label="Password confirmation" required outlined dense></v-text-field>
                                </v-col>
                              </v-row>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </div>
                  <v-textarea class="mini" v-model="formData.internal_notes" :rules="[]" label="Notes." outlined dense></v-textarea>

                  <FilesList v-if="formData.id" :entity-id="formData.id" entity-type="counterparty" :roles="formData.roles" />

                  </v-col>
                  <v-col md="6" v-if="isCustomer || isCarrier">
                    <v-card outlined>
                      <v-card-text>
                        <blocking-layover :blocking-condition="editAccess()">
                          <div v-if="isCustomer" :transition="false">
                            <v-autocomplete class="mini" v-model="formData.carrier_data.factoring_company" item-text="name"
                              return-object :items="factoringCom" label="Factoring company" persistent-hint outlined dense
                              clearable></v-autocomplete>
                            <v-row>
                              <v-col md="6">
                                <v-select class="mini" v-model="formData.default_currency_id" :items="currencies"
                                  label="Default currency" item-text="name" item-value="id" hide-details persistent-hint outlined
                                  dense></v-select>
                              </v-col>
                              <v-col md="6">
                                <TaxSelect class="mini" outlined dense v-model="formData.customer_data.vat_rate"
                                  return-object clearable required  hide-details />
                              </v-col>
                              <v-col md="6">
                                <v-select class="mini" v-model="formData.customer_data.payment_term_id"
                                  :items="paymentTerms" label="Payment Terms" item-text="name" item-value="id"
                                  persistent-hint hide-details outlined dense></v-select>
                              </v-col>
                              <v-col md="6">
                                <v-text-field label="Payments balance" :value="formData.customer_data.payments_balance" class="mini" readonly outlined dense />
                              </v-col>
                              <v-col md="6">
                                <v-text-field :style="$store.getters.role.includes('customer_support') && !formData.customer_data.credit_limit ? 'pointer-events:all' : ''" :disabled="$store.getters.role.includes('dispatcher') || $store.getters.role.includes('sales_representant')" class="mini" v-model="formData.customer_data.credit_limit"
                                  label="Credit limit" required outlined dense></v-text-field>
                              </v-col>
                              <v-col md="6">
                                  <v-text-field class="mini" :style="$store.getters.role.includes('customer_support') && !formData.customer_data.credit_limit_balance ? 'pointer-events:all' : ''" v-model="formData.customer_data.credit_limit_balance" label="Credit limit balance" :readonly="$store.getters.role.includes('dispatcher') || $store.getters.role.includes('sales_representant')" outlined dense></v-text-field>
                              </v-col>
                            </v-row>
                            <v-select class="mini" v-model="formData.customer_data.sales_representant_id" item-text="name"
                              item-value="id" :items="salesRep" label="Sales representant" persistent-hint outlined dense
                              clearable></v-select>
                            <v-text-field class="mini" v-model="formData.customer_data.federal_id" label="DUNS #" required
                              outlined dense></v-text-field>
                            <v-text-field class="mini" v-model="formData.customer_data.workers_company_number"
                              label="Worker's Comp #" required outlined dense></v-text-field>
                            <v-checkbox class="mini" v-model="formData.customer_data.show_contacts_on_invoice"
                              label="Show tel. and fax numbers on invoices" outlined dense></v-checkbox>
                            <v-checkbox class="mini" v-model="formData.customer_data.show_detailed_rate_on_invoice"
                              label="Show Detailed Rate on Invoice" outlined dense></v-checkbox>
                          </div>
                        </blocking-layover>

                        <div v-if="isCarrier" :transition="false">
                          <v-select class="mini" :value="formData.carrier_data.factoring_company" @change="setFactoringCompany" item-text="name"
                            return-object :items="factoringCom" label="Factoring company" persistent-hint outlined dense
                            clearable></v-select>
                          <v-select class=" mini" v-model="formData.carrier_data.default_currency" :items="currencies"
                            label="Default currency" persistent-hint outlined dense></v-select>
                          <v-row>
                            <v-col>
                              <v-select class="mini" v-model="formData.carrier_data.payment_terms_id" :items="paymentTerms"
                                        label="Payment Terms" item-text="name" item-value="id" persistent-hint outlined
                                        dense></v-select>
                            </v-col>
                            <v-col>
                              <v-text-field label="Payments balance" :value="formData.carrier_data.payments_balance" class="mini" readonly outlined dense />
                            </v-col>
                          </v-row>
                          <v-text-field v-model="formData.carrier_data.tax_id" :rules="[]" label="Tax ID #" class="mini"
                            outlined dense></v-text-field>
                          <v-row>
                            <v-col>
                              <v-select class="mini" v-model="formData.carrier_data.mc_ff_type" :items="['mc', 'cbi']"
                                label="M.C. / C.B.I" persistent-hint outlined dense></v-select>
                            </v-col>
                            <v-col>
                              <v-text-field class="mini" v-model="formData.carrier_data.mc_ff_value" :rules="[]"
                                label="M.C. # / C.B.I #" outlined dense></v-text-field>
                            </v-col>
                          </v-row>
                          <v-text-field class="mini" v-model="formData.carrier_data.urs" :rules="[]" label="URS #"
                            outlined dense></v-text-field>
                          <v-text-field class="mini" v-model="formData.carrier_data.dot" :rules="[]" label="D.O.T."
                            outlined dense></v-text-field>
                          <div :class="`d-flex ${formData.carrier_data.is_blacklisted && formData.carrier_data.blacklist_notes ? 'align-items-start' : 'align-items-center'}`">
                            <div>
                              <v-checkbox class="mini mr-5" v-model="formData.carrier_data.is_blacklisted"
                                label="Blacklisted" outlined dense></v-checkbox>
                              <div v-if="formData.carrier_data.is_blacklisted && formData.carrier_data.blacklist_notes">
                                <u>REASON</u>: {{ formData.carrier_data.blacklist_notes }}
                              </div>
                            </div>
                            <v-checkbox class="mini" v-model="formData.carrier_data.is_corporation" label="Corporation"
                              outlined dense></v-checkbox>
                          </div>
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item :transition="false">

                <CounterpartyAddresses :counterparty="formData"></CounterpartyAddresses>
                <v-divider></v-divider>
                <CounterpartyContacts :counterparty="formData"></CounterpartyContacts>
              </v-tab-item>
              <v-tab-item :transition="false" v-if="isCarrier">
                <v-row>
                  <v-col md="6" v-for="(insurance, index) in formData['counterparty_insurances']">
                    <v-card outlined dense class="mb-3">
                      <v-card-text>
                        <CounterpartyInsurance :insurance="insurance"></CounterpartyInsurance>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn x-small color="red" text @click="removeIns(index)">Remove</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                  <v-col><v-btn @click="addIns" small text color="green"> + Add new insurance</v-btn></v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item :transition="false">

                <v-select class="mini" :disabled="editAccess()" v-model="formData.status" :items="['active', 'inactive']" persistent-hint
                      outlined dense></v-select>
                <v-select class="mini" :disabled="role || editAccess() ? true : false" v-model="formData.roles" :items="roles"
                  :menu-props="{ maxHeight: '400' }" label="Roles" multiple outlined dense></v-select>
              </v-tab-item>
            </v-tabs-items>

            <v-row>
              <v-col md="12">
                <v-row>


                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="swDialog" width="1200px">
      <sw :close.sync="swDialog" :carrier-data.sync="formData"></sw>
    </v-dialog>
    <v-dialog v-model="openSaferWatch" width="700">
      <v-card>
        <v-toolbar dark color="primary" dense>
          <v-toolbar-title>
            {{ formData.name }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn dark text>
            View more
          </v-btn>
          <v-btn icon dark @click="openSaferWatch = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <CounterpartySaferWatch :safer-watch="formData.counterparty_saferwatch" />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="files" width="1000px" max-height="800px" scrollable>
      <v-card>
        <v-toolbar dense dark color="primary">
          <v-toolbar-title>File manager {{ formData.name ?? "" }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon dark @click="files = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text class="pt-3">
          <Files :type="'counterparty'" :id="formData.id" />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" :timeout="2000" color="success">
      <v-icon>mdi-check</v-icon> Successfully saved!

      <template v-slot:action="{ attrs }">
        <v-btn text color="white" v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
      <v-snackbar
          v-model="errorSnackbar"
          :timeout="3000"
          color="red accent-2"
      >
          <b>{{ serverError }}</b>
      </v-snackbar>
  </v-row>
</template>

<script>
import CounterpartyAddresses from "@/components/counterparty/CounterpartyAddresses";
import CounterpartyContacts from "@/components/counterparty/CounterpartyContacts";
import config from "@/config";
import axios from "@/plugins/axios";
import CounterpartyInsurance from "@/components/counterparty/CounterpartyInsurance";
import CounterpartySaferWatch from "@/components/counterparty/CounterpartySaferWatch";
import BlockingLayover from "../layout/BlockingLayover.vue";
import Files from "@/components/files/List.vue";
import TaxSelect from "@/components/loads/TaxSelect.vue";
import FilesList from "@/components/tools/FilesList.vue";
export default {
  name: "CounterpartyEdit",
  components: {FilesList, TaxSelect, BlockingLayover, CounterpartyInsurance, CounterpartyContacts, CounterpartyAddresses, CounterpartySaferWatch, Files },
  props: {
    counterparty: {
      type: [Object, null]
    },
    role: {
      type: [String, null]
    }
  },
  data: () => ({
    swDialog: false,
    files: false,
    snackbar: false,
    importSaferWatch: false,
    openSaferWatch: false,
    createAccount: false,
    mcInput: false,
    formData: null,
    dialog: true,
    saveInProgress: false,
    tab: 0,
    counterpartyTabs: null,
    roles: [
      'admin',
      'dispatcher',
      'sales_representant',
      'carrier',
      'customer',
      'shipper',
      'consignee',
      'customs_broker',
      'factoring_company',
      'customer_support',
      'accounting'
    ],
    teams: [],
    currencies: ['USD', 'CAD'],
    paymentTerms: [],
    swId: '',
    swType: '',
    importCarrierInProgress: false,
    validation: {
      nameRules: [
        v => !!v || 'Name is required',
      ],
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+/.test(v) || 'E-mail must be valid',
      ]
    },
    salesRep: [],
    factoringCom: [],
    serverError: null,
    errorSnackbar: false
  }),
  created() {
    this.formData = JSON.parse(JSON.stringify(this.counterparty));
    console.log(this.counterparty);
    console.log(JSON.parse(JSON.stringify(this.counterparty)))
    if (this.formData['counterparty_insurances'].length >= 1) {
      this.formData.counterparty_insurances = this.formData['counterparty_insurances']
    } else {
      this.formData.counterparty_insurances = [];
    }

    this.formData['customer_data'] = this.formData['customer_data'] || {
      "payment_term_id": null,
      "credit_limit": null,
      "sales_representant_id": null,
      "factoring_company_id": null,
      "federal_id": null,
      "workers_company_number": null,
      "show_contacts_on_invoice": true,
      "show_detailed_rate_on_invoice": false,
      "internal_notes": null,
      "vat_rate": null,
    };

    this.formData['carrier_data'] = this.formData['carrier_data'] || {
      "payment_terms_id": null,
      "tax_id": null,
      "mc_ff_type": "mc",
      "mc_ff_value": "",
      "urs": null,
      "dot": "",
      "notes": null,
      "is_blacklisted": false,
      "blacklist_notes": '',
      "is_corporation": false,
      "factoring_company": null,
      "status": true
    };
    if (this.role) {
      this.formData.roles = [this.role]
    }

    if (!this.formData['user']) {
      this.formData['user'] = {
        password: ''
      }
    }

    this.$watch('formData.carrier_data.is_blacklisted', (val) => {
      if (val) {
        let blacklistNotes = prompt('Specify the blacklist reason')
        if(blacklistNotes) {
          this.formData.carrier_data.blacklist_notes = blacklistNotes
        } else {
            blacklistNotes = prompt('You haven`t specified the blacklist reason. Please type again and confirm')
            if (blacklistNotes) {
              this.formData.carrier_data.blacklist_notes = blacklistNotes
            } else this.$nextTick(() => this.formData.carrier_data.is_blacklisted = false)
        }
      } else {
        this.formData.carrier_data.blacklist_notes = null
      }
    })
  },
  mounted() {
    axios.get(config.apiUrl + '/api/team').then((response) => {
      this.teams = response.data;
    });
    axios.get(config.apiUrl + '/api/counterparty?role[]=sales_representant').then((response) => {
      this.salesRep = response.data.data;
    });
    axios.get(config.apiUrl + '/api/counterparty?role[]=factoring_company&itemsPerPage=1000').then((response) => {
      this.factoringCom = response.data.data;
    });
    axios.get(config.apiUrl + '/api/payment-term').then((response) => {
      this.paymentTerms = response.data;
    });
  },
  computed: {
    haveCredentials() {
      return this.formData.roles.includes('admin') || this.formData.roles.includes('dispatcher') || this.formData.roles.includes('sales_representant');
    },
    isCustomer() {
      return this.formData.roles.includes('customer');
    },
    isCustomerSupport() {
      return this.formData.roles.includes('customer_support');
    },
    isCarrier() {
      return this.formData.roles.includes('carrier');
    },
    isSales() {
      return this.formData.roles.includes('sales_representant');
    },
    isDispatcher() {
      return this.formData.roles.includes('dispatcher');
    },
    certificateExists() {
      return this.formData.roles.includes('carrier') && this.formData.counterparty_saferwatch && this.formData.counterparty_saferwatch.certificate_data && this.formData.counterparty_saferwatch.certificate_data.certificateID
    },
    isCustomsBroker() {
      return this.formData.roles.includes('customs_broker');
    },
    isCA() {
      return this.formData.counterparty_addresses.findIndex(a => a.country_code === 'CA') !== -1;
    }
  },
  methods: {
    setFactoringCompany(event) {
      this.formData.carrier_data.factoring_company = event
      if(!this.formData.carrier_data.factoring_company) this.formData.carrier_data.factoring_company_id = null
    },
    editAccess() {
      return this.$store.getters.role.some(role => {
        return role === 'sales_representant' || role === 'customer_support'
      })
    },
    changeStatus(res) {
      console.log(res)
    },
    addIns() {
      this.formData.counterparty_insurances.push({
        "company_name": null,
        "policy_number": null,
        "policy_expiration": null,
        "amount": null,
        "phone": null,
        "contact_name": null,
      });
    },

    removeIns(item) {
      this.formData.counterparty_insurances.splice(item, 1);
    },
    checkSW() {
      this.swDialog = true
    },
    save(andContinue = true) {
      if (this.$refs.form.validate()) {
        if (this.isCustomer && !this.formData.customer_data.vat_rate && this.isCA) {
          alert('Taxes field is required for customer');
          return;
        }
        this.saveInProgress = true;
        this.$store.dispatch('counterparty/save', this.formData)
          .then((response) => {
            this.formData.id = response.data.id
            this.saveInProgress = false;
            this.$emit('saved', response.data)
            this.snackbar = true
          })
          .catch((error) => {
              this.serverError = error.response.data.message
              this.errorSnackbar = true
          })
          .finally(() => {
            this.saveInProgress = false;
          })
      }
    },
    importCarrier() {
      this.importCarrierInProgress = true
      axios.post(config.apiUrl + '/api/counterparty/carrier/saferwatch?identity=' + this.swType + this.swId).then((response) => {
        const id = this.formData['id'] || null
        this.formData = response.data
        this.formData['id'] = id
      })
        .catch(() => { })
        .finally(() => {
          this.importCarrierInProgress = false
          this.importSaferWatch = false
        });
    },
    downloadCertificate() {
      axios.get(config.apiUrl + '/api/counterparty/' + this.counterparty.id + '/download-certificate')
        .then(response => {
          window.open(response.data.displayMsg, '_blank', 'noreferrer');
        })
    }
  }
}
</script>

<style scoped></style>
