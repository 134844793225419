<template>
  <div class="pt-3 mx-auto">
    <v-row>
      <v-col>
        <div>
          <img src="@/assets/safer_watch.jpg" alt="">
        </div>
        <div class="mt-3 font-italic">
          <strong>Last updated: </strong>
          {{ saferWatch.updated_at }}
        </div>
        <div class="my-3">
            <span class="mr-5">SaferWatch Overall Carrier Risk Indicator:</span>
            <v-chip outlinedlabel>
            {{ saferWatch.risk_assessment.Overall }}
            </v-chip>
        </div>
        <v-row>
          <v-col>
            <v-card outlined>
              <v-card-text>
                <strong>CSA Basics</strong>
                <v-divider></v-divider>
                <ul class="saferList">
                  <li>
                    <span>Unsafe Driving</span>
                    <v-chip small color="black" outlined label>
                      {{ saferWatch.safety.unsafeDrvPCT }}
                    </v-chip>
                  </li>
                  <li>
                    <span>HOS Compliance</span>
                    <v-chip small color="black" outlined label>
                      {{ saferWatch.safety.hosPCT }}
                    </v-chip>
                  </li>
                  <li>
                    <span>Driver Fitness</span>
                    <v-chip small color="black" outlined label>
                      {{ saferWatch.safety.drvFitPCT }}
                    </v-chip>
                  </li>
                  <li>
                    <span>Drugs/Alcohol</span>
                    <v-chip small color="black" outlined label>
                      {{ saferWatch.safety.controlSubPCT }}
                    </v-chip>
                  </li>
                  <li>
                    <span>Vehicle Maintenance</span>
                    <v-chip small color="black" outlined label>
                      {{ saferWatch.safety.vehMaintPCT }}
                    </v-chip>
                  </li>
                </ul>
              </v-card-text>
            </v-card>
            <v-card outlined class="mt-3">
              <v-card-text>
                <strong>Out of Service</strong>
                <v-divider></v-divider>
                <v-chip v-if="saferWatch.operation.outOfService != 'Yes'" color="green" text-color="white" label class="w-100">
                  No
                </v-chip>
                <v-chip v-else color="red"  text-color="white" label class="w-100">
                  {{ saferWatch.operation.outOfService }}
                </v-chip>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col>
            <v-card outlined>
              <v-card-text>
                <strong>Safety Rating</strong>
                <v-divider></v-divider>
                <v-chip v-if="saferWatch.safety.rating != 'Satisfactory'" color="red" text-color="white" label class="w-100">
                  Not Rated
                </v-chip>
                <v-chip v-else color="green"  text-color="white" label class="w-100">
                  {{ saferWatch.safety.rating }}
                </v-chip>
              </v-card-text>
            </v-card>
            <v-card class="mt-3" outlined>
              <v-card-text>
                <strong>Authority Status</strong>
                <v-divider></v-divider>
                <ul class="saferList">
                  <li>
                    <span>Common/Contract</span>
                    <v-chip v-if="saferWatch.operation.entityCarrier != 'No'" small color="green" text-color="white" label>
                      Active
                    </v-chip>
                    <v-chip v-else small color="black" outlined label>
                      None
                    </v-chip>
                  </li>
                  <li>
                    <span>Broker</span>
                    <v-chip v-if="saferWatch.operation.entityBroker != 'No'" small color="green" text-color="white" label>
                      Active
                    </v-chip>
                    <v-chip v-else small color="black" outlined label>
                      None
                    </v-chip>
                  </li>
                </ul>
              </v-card-text>
            </v-card>
            <v-card outlined class="mt-3">
              <v-card-text>
                <strong>Fleet Size</strong>
                <v-divider></v-divider>
                <ul class="saferList">
                  <li class="mb-0">
                    <span>Number Of Trucks	</span>
                    <v-chip color="black" outlined label small>
                      {{ saferWatch.equipment.fleetsize }}
                    </v-chip>
                  </li>
                </ul>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "CounterpartySaferWatch",
  props: {
    saferWatch: {
      type: Object,
      required: true
    }
  },
  computed: {
  
  },
  methods: {
    downloadCertificate () {
      axios.get(config.apiUrl + '/api/counterparty/' + this.counterparty.id + '/download-certificate')
        .then(response => {
          window.open(response.data.displayMsg, '_blank', 'noreferrer');
        })
    }
  }
}
</script>

<style scoped>

</style>
