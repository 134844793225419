<template>
  <div>
    <v-dialog v-if="load" :value="true" max-width="1600px" overlay-opacity="0.5" :transition="false"
      @click:outside="closeLoad" @keydown.esc="closeLoad" persistent light>
      <v-form ref="loadForm" :disabled="!allowEdit">

        <v-card class="load-modal" light>
          <v-toolbar flat dense dark color="black">
            <v-toolbar-title tag="span" class="subtitle-1" v-if="!load.id">Add new load</v-toolbar-title>
            <v-toolbar-title tag="span" class="subtitle-1" v-if="load.id">Edit load - #{{ load.id }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-card-actions class="d-flex flex-column flex-md-row align-start align-md-center">
              <v-chip small label class="my-1 mr-lg-1">
                <span class="font-weight-bold mr-1 text-disabled">Order #</span>
                {{ this.load.order_id }}
              </v-chip>
              <v-chip small label class="my-1 mx-lg-1">
                <span class="font-weight-bold mr-1 text-disabled">Created at</span>
                {{ moment(this.load.created_at).format('YYYY-MM-DD HH:mm:ss') }}

                <span v-if="this.load.created_by?.name !== undefined" class="font-weight-bold mx-1 text-disabled"
                  v-text="'by'" />

                {{ this.load.created_by?.name || '' }}
              </v-chip>
              <v-chip small label class="my-1 ml-lg-1">
                <span class="font-weight-bold mr-1 text-disabled">Updated at</span>
                {{ moment(this.load.updated_at).format('YYYY-MM-DD HH:mm:ss') }}

                <span v-if="this.load.updated_by?.name !== undefined" class="font-weight-bold mx-1 text-disabled"
                  v-text="'by'" />

                {{ this.load.updated_by?.name || '' }}
              </v-chip>
            </v-card-actions>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn icon @click="closeLoad">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar-items>

          </v-toolbar>
          <v-card flat
            :class="['p-3']"
            light>
            <div class="load_layover" style="position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              z-index:9999;
              opacity:1" v-if="loadLocked">
              <template v-if="(typeof loadLocked === 'string')">You can`t edit this load, because it`s already editing
                by&nbsp;<strong><u>{{ loadLocked }}</u></strong></template>
            </div>
            <v-card-text :style="loadLocked ? 'opacity:.5; pointer-events:none' : ''">
              <v-row>
                <v-col
                  :md="(load.load_carriers[0].load_carrier_shippers[0].location && load.load_carriers[0].load_carrier_consignees[0].location) ? '12' : '12'">

                    <v-card outlined :class="['px-3', { 'load_layover--bg': restrictedEdit ?? loadLocked, 'load_layover--bg_blurred': typeof loadLocked === 'string' }]" light>
                      <v-card-text>
                        <v-row align="center" dense>
                          <v-col md="3">
                            <blocking-layover :blocking-condition="restrictedEdit">
                              <CounterpartySelect :clearable="!restrictedEdit" @input="onCounterpartyChanged" v-model="load.load_finances.customer"
                              :roles="['customer']" required />
                            </blocking-layover>
                          </v-col>
                          <v-col md="4">
                            <LoadLocationSelect :label="'Billing address'"
                              :counterpartyId="load.load_finances.customer ? load.load_finances.customer.id : null"
                              v-model="load.load_finances.location" @change="onLocationSelected" @input="onLocationInput">
                            </LoadLocationSelect>
                          </v-col>
                          <v-col md="3">
                            <CounterpartySelect v-model="load.dispatcher" :roles="['dispatcher']" />
                          </v-col>
                          <v-col class="d-none" md="2">
                            <CounterpartySelect use-select v-model="load.load_sale_representants" multiple :roles="['sales_representant']" />
                          </v-col>
                          <v-col md="2">
                              <LoadStatusSelect
                                :style="loadStatusStyle"
                                v-model="load.status"></LoadStatusSelect>
                          </v-col>
                        </v-row>
                        <v-row align="center" dense>
                          <v-col md="12">
                            <v-text-field v-model="load.wo" class="mini" hide-details="auto" label="W/O" outlined dense
                              required clearable>
                            </v-text-field>
                          </v-col>
                          <v-col class="d-none" md="1">
                            <v-text-field v-model.number="load.load_finances.rate" class="mini" hide-details="auto"
                              label="Rate" outlined dense>
                            </v-text-field>
                          </v-col>
                          <v-col class="d-none" md="1">
                            <v-text-field v-model.number="load.load_finances.pds" class="mini" hide-details="auto"
                              label="P/Ds" outlined dense clearable></v-text-field>
                          </v-col>
                          <v-col class="d-none" md="1">
                            <v-text-field v-model="load.load_finances.fsc_value" class="mini icon-green"
                              :append-icon="load.load_finances.fsc_type === 'percent' ? 'mdi-percent' : 'mdi-numeric'"
                              type="number" label="F.S.C. / Rate" hide-details="auto" outlined dense
                              @click:append="load.load_finances.fsc_type = load.load_finances.fsc_type === 'percent' ? 'amount' : 'percent'"></v-text-field>
                          </v-col>
                          <v-col class="d-none" md="1">
                            <v-text-field :value="customerOtherCharges" class="mini icon-green" hide-details="auto"
                              label="Other charges" @click="openCustomerOtherCharges" append-icon="mdi-cash-plus" outlined
                              dense readonly>
                            </v-text-field>
                          </v-col>
                          <v-col class="d-none" md="1">
                            <v-select class="mini" v-model="currencyId" hide-details="auto" :items="currency"
                              label="Currency" outlined dense clearable>
                            </v-select>
                          </v-col>
                          <v-col class="d-none" md="2">
                            <TaxIncludedSelect v-model="load.load_finances.taxes" hide-details="auto" class="mini" outlined
                              dense />
                          </v-col>
                          <v-col class="d-none" md="1">
                            <TaxSelect v-model="load.load_finances.vat_rate" return-object class="mini" hide-details="auto"
                              outlined dense clearable></TaxSelect>
                          </v-col>
                          <v-col class="d-none" md="1">
                            <v-text-field :value="load.load_finances.total" class="mini" hide-details="auto" label="Total"
                              outlined dense readonly></v-text-field>
                          </v-col>
                          <v-col class="d-none" md="1">
                            <v-text-field v-if="rate && !isNaN(rate)" :value="rate + '%'" label="Percent" readonly
                              class="mini icon-green" hide-details="auto" outlined dense></v-text-field>
                          </v-col>
                        </v-row>
                    </v-card-text>
                  </v-card>
                  <blocking-layover :blocking-condition="restrictedEdit" style="height:auto">
                    <Carriers @openRC="rcView(load.id, $event, 1, 'carrier')" :load="load" :carriers="load.load_carriers"
                      class="my-3" />
                  </blocking-layover>

                  <v-card-actions>
                    <v-btn :style="!restrictedEdit ? 'pointer-events:all;z-index:10000' : ''" v-if="load.id" small color="primary" class="px-5" @click="files = true">
                      <v-icon small>
                        mdi-attachment
                      </v-icon>
                    </v-btn>
                        <v-btn v-if="load" small class="px-5 mx-3" @click="notesOpened = true">
                          <v-icon small>
                            mdi-comment-text-multiple-outline
                          </v-icon>
                        </v-btn>
                        <LoadNotes
                            :load-id="load.id"
                            @close="handleLoadNotesClose($event)"
                            :is-notes-opened="notesOpened"
                        />
                    <div class="d-none">
                        <v-btn
                          v-if="load.id && load.load_carriers[0].load_carrier_shippers[0].shipper_id && load.load_carriers[0].load_carrier_consignees[0].consignee_id"
                          small dark color="cyan darken-1" class="ml-0 px-5" @click="rcView(load.id, 0, 0, 'customer')">
                          <v-icon small class="mr-3">
                            mdi-file
                          </v-icon>
                          Rate confirmation
                        </v-btn>
                    </div>
                    <span class="d-none" v-if="load.id">
                      <BillOfLanding :load="load"></BillOfLanding>
                    </span>
                      <v-btn v-if="load" small class="px-5 mx-2" color="blue-grey lighten-4" disabled>
                          <v-icon small class="mr-3">
                            mdi-relation-one-to-many
                          </v-icon>
                          EDI
                      </v-btn>
                      <div>
                          <v-btn small @click.stop="openComments(load.id)" color="success" dark>
                                <v-badge v-if="load.comments_count > 0" :content="load.comments_count" overlap>
                                  <v-icon >mdi-comment-outline</v-icon>
                                </v-badge>
                                <v-icon v-else >mdi-comment-outline</v-icon>
                          </v-btn>
                      </div>
                    <v-spacer></v-spacer>
                    <v-btn small color="default" class="px-5 mr-1" @click="duplicate()">
                      <v-icon small class="mr-3">
                        mdi-content-save-all-outline
                      </v-icon>
                      Duplicate
                    </v-btn>
                    <div class="mx-1">
                      <blocking-layover :blocking-condition="restrictedEdit" :bluring-condition="restrictedEdit">
                        <v-btn v-if="allowEdit" small color="success" class="px-5" @click="save(false)">
                          <v-icon small class="mr-3">
                            mdi-content-save
                          </v-icon>
                          Save
                        </v-btn>
                      </blocking-layover>
                    </div>
                  </v-card-actions>


                </v-col>

              </v-row>
            </v-card-text>

            <v-progress-linear :active="loading" :indeterminate="loading" absolute bottom width="100" height="10"
              color="secondary"></v-progress-linear>
          </v-card>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog v-if="load" v-model="showCustomerOtherCharges" max-width="1000px" overlay-opacity="0.5">
      <v-card>
        <v-toolbar flat dense dark color="secondary">
          <v-toolbar-title>Other charges</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <OtherCharges v-model="load.load_finances.other_charges"></OtherCharges>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-if="load" v-model="rc_client" width="1000px" max-height="800px" scrollable @click:outside="rc_url = null"
      @keydown.esc="rc_url = null">
      <v-card>
        <v-toolbar dense dark color="primary">
          <v-btn icon dark @click="rc_client = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Rate confirmation for Load #{{ load.id }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="downloadRC">
              <v-icon>mdi-download-outline</v-icon>
              Download
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <iframe style="border:0" :src="this.rc_url" width="100%" height="1000px"></iframe>
        <div class="d-flex p-3 justify-center" style="border-top: 1px solid black">
          <div class="d-flex align-center">
            <input type="radio" :value="0" v-model="sign_directly" class="mr-2">
            <input type="email" @focus="sign_directly = 0" v-model="sign_custom_email" placeholder="Send to custom e-mail" style="max-width:200px;border-radius:4px" class="mr-2 p-1">
          </div>
          <div class="d-flex align-center mr-4">
            <input id="radio" type="radio" :value="1" v-model="sign_directly" class="mr-2">
            <label for="radio" style="margin-bottom:0px">Send directly(Using contacts of affected counterparty)</label>
          </div>
          <v-btn @click="signLoad(load)" color="success">Sign</v-btn>
        </div>
      </v-card>
    </v-dialog>
    <FilesModal v-if="load" :files="files" @close="files = false" :additionalData="additionalData" :load="load" :restrictedEdit="restrictedEdit" @setCarrierFactoringCompany="setCarrierFactoringCompany($event)"></FilesModal>
    <v-dialog :value="loadComments" max-width="800" @click:outside="loadComments = null">
      <v-card>
        <v-card-text>
          <Comments :load-id="loadComments" :load="load" />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="savedSnackbar" :timeout="timeout">
      Load saved!

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="savedSnackbar = false">Close</v-btn>
      </template>
    </v-snackbar>
    <v-snackbar v-model="signSent" :timeout="timeout">
      The sign has been sent!

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="signSent = false">Close</v-btn>
      </template>
    </v-snackbar>
    <v-snackbar color="red" v-model="signError" :timeout="5000">
      We couldn`t find the e-mail contact of this counterparty. Please fill it in the counterparty profile or send it to specified e-mail!

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="signError = false">Close</v-btn>
      </template>
    </v-snackbar>
    <v-snackbar v-model="errorSnackbar" :timeout="timeout" color="red">
      Load has issues!

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="errorSnackbar = false">Close</v-btn>
      </template>
    </v-snackbar>
    <v-dialog :value="loadUnsaved" persistent max-width="500">
      <v-card>
        <v-card-title class="text-h5">
          You have unsaved changes, discard?
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" small dark @click="loadUnsaved = false">
            No
          </v-btn>
          <v-btn color="red darken-1" small dark @click="$store.state.loadInEdit = null">
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>
  </div>
</template>

<script>
import Carriers from '../loads/carriers/List.vue';
import CounterpartySelect from "@/components/counterparty/CounterpartySelect";
import axios from "@/plugins/axios";
import config from "@/config";
import LoadStatusSelect from "@/components/loads/LoadStatusSelect";
import OtherCharges from "@/components/loads/OtherCharges";
import LoadLocationSelect from "@/components/loads/LoadLocationSelect";
import FilesModal from "@/components/files/Modal.vue";
import * as dayjs from "dayjs";
import * as moment from 'moment'
import BillOfLanding from "@/components/loads/bol/BillOfLanding";
import TaxIncludedSelect from "@/components/loads/TaxIncludedSelect.vue";
import TaxSelect from "@/components/loads/TaxSelect.vue";
import Comments from "@/components/loads/Comments.vue";
import BlockingLayover from './BlockingLayover.vue';
import loadLayoverMixin from '@/mixins/loadLayoverMixin';
import LoadNotes from "@/components/loads/LoadNotes.vue";

export default {
  components: {
    LoadNotes,
    TaxSelect,
    TaxIncludedSelect,
    FilesModal,
    BillOfLanding,
    OtherCharges,
    LoadStatusSelect,
    CounterpartySelect,
    Carriers,
    LoadLocationSelect,
    Comments,
    BlockingLayover
  },
  mixins: [
    loadLayoverMixin
  ],
  created() {
    if (this.load) {
      if((this.load.status === 'completed' || this.load.status === 'invoiced' || this.load.status === 'delivered')) {
        if (this.$store.state.auth.user.counterparties.some(counterparty => {
          return counterparty.roles.some(role => role === 'sales_representant' || role === 'dispatcher')
        }) && this.load.status !== 'invoiced') {
          this.loadLocked = false;
        } else if (!this.$store.state.auth.user.counterparties.some(counterparty => {
          return counterparty.roles.some(role => role === 'admin' || role === 'accounting' || (role === 'customer_support' && this.load.status !== 'invoiced'))
        })) {
          this.loadLocked = true;
        }
      }
    }
    setInterval(() => {
      if (this.load) {
        let inc = 0
        if (this.load.load_finances.rate) {
          inc += parseFloat(this.load.load_finances.total)
        }

        let exp = 0
        this.load.load_carriers.forEach(carrier => {
          if (carrier.total_amount) {
            exp += parseFloat(carrier.total_amount)
          }
        })
        this.rate = ((inc - exp) / inc * 100).toFixed()
      }
    }, 250)
  },
  data() {
    return {
      sign_directly: 1,
      sign_custom_email: '',
      originalLoad: null,
      signError: false,
      loadLocked: undefined,
      loadComments: null,
      loadLockInterval: undefined,
      loadUnsaved: false,
      unsaved: undefined,
      bolId: null,
      bolItemDialog: false,
      bolItem: {
        third: null,
        carrier: [],
        freight: null,
        origin: null,
        destination: null,
        emergency: null,
        cod: null,
        cod_value: null,
        value: null,
        notes: null,
        items: null
      },
      salesRep: null,
      billDialog: false,
      signSent: false,
      moment: moment,
      files: false,
      rc_client: false,
      rc_url: null,
      rc_role: '',
      rc_carrier: null,
      tab: null,
      loading: false,
      savedSnackbar: false,
      errorSnackbar: false,
      timeout: 2000,
      tabConsignees: null,
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      items: ['open', 'In Yard', 'Completed'],
      fin_type: ['Line Haul'],
      transport: ['Carrier', 'Driver'],
      transport_default: 'Carrier',
      fsc_percent: false,
      currency: ['USD', 'CAD'],
      currency_default: 'USD',
      loadInEdit: {},
      showCustomerOtherCharges: false,
      notesOpened: false,
      rate: '-',
      bills: [
        {
          third: null,
          carrier: null,
          freight: null,
          origin: null,
          destination: null,
          emergency: null,
          cod: null,
          cod_value: null,
          value: null,
          notes: null,
          items: null
        },
      ]

    }
  },
  beforeDestroy() {
    clearInterval(this.loadLockInterval)
  },
  mounted() {
    if(this.load && (this.load.status === 'completed' || this.load.status === 'delivered' || this.load.status === 'invoiced')) {
      this.$store.state.loadCompleted = true
    } else this.$store.state.loadCompleted = false
    this.$socket.on('locked', (data) => {
      this.loadLocked = data.name
    })

    this.$socket.on('unlock', (data) => {
      this.$emit('unlocked', {
        id: data.id,
        refresh: false
      })
      this.loadLocked = undefined
    })

    if (this.load && this.load.load_finances.customer !== null && this.load.load_finances.currency === null) {
      this.load.load_finances.currency = this.load.load_finances.customer.default_currency_id ? this.load.load_finances.customer.default_currency_id : 'USD'
    }
    this.$nextTick(() => {
      this.$watch('load', (newVal, oldVal) => {
        this.unsaved = false
      }, { deep: true })

      this.loadLockInterval = setInterval(() => {
        if (this.load) {
          this.$socket.emit('lock', {
            id: this.load.id,
            type: 'load',
            name: this.$store.state.auth.user.name
          })
        }
      }, 1000)
    })
  },
  computed: {
    additionalData() {
      return {
        load_file_type: this.load.load_carriers.length === 1 ? 'single' : 'multiple'
      }
    },
    loadStatusStyle() {
      if (this.$store.getters.role.includes('admin') && this.$store.state.loadCompleted) {
        return 'pointer-events: all'
      }
      if(!this.haveUnuploadedFiles(this.load) === false && this.$store.state.loadCompleted) {
        return 'pointer-events:all'
      }
      return (this.$store.getters.role.includes('sales_representant') || this.$store.getters.role.includes('dispatcher')) && this.load.status === 'invoiced' && this.$store.state.loadCompleted ? 'pointer-events:none' : ''
    },
    restrictedEdit() {
      if (this.$store.getters.role.includes('accounting') || this.$store.getters.role.includes('admin') || (this.$store.getters.role.includes('customer_support') && this.load.status === 'invoiced') || ((this.$store.getters.role.includes('dispatcher') || this.$store.getters.role.includes('sales_representant') && (this.load.status === 'delivered' || this.load.status === 'completed')))) {
        return false
      }
      if(this.$store.getters.role.includes('customer_support') && this.load.status === 'completed') return false
      return this.restrictLoadEdit(this.load)
    },
    allowEdit() {
      const role = this.$store.getters.role;
      const status = this.originalLoad.status || '';
      return (
        (role.includes('admin') || role.includes('accounting')) ||
        ((role.includes('dispatcher') || role.includes('sales_representant')) && status !== 'invoiced') ||
        (!role.includes('admin') && !role.includes('dispatcher') && (role.includes('customer_support') ? !['invoiced'].includes(status) : !['invoiced', 'completed', 'delivered'].includes(status)))
      );
    },
    currencyId: {
      get() {
        if (this.load.load_finances.currency) {
          return this.load.load_finances.currency
        } else if (this.load.load_finances.customer !== null) {
          return this.load.load_finances.currency = this.load.load_finances.customer.default_currency_id
        }
        return null
      },
      set(value) {
        this.load.load_finances.currency = value
      }
    },
    dateDefault() {
      return dayjs().format('YYYY-MM-DD')
    },
    load() {
      if (!this.originalLoad) {
        this.updateOriginalLoad();
      }
      return this.$store.state.loadInEdit;
    },
    customerOtherCharges() {
      let value = 0;

      if (!Array.isArray(this.load.load_finances.other_charges)) {
        return null
      }

      this.load.load_finances.other_charges.forEach(item => {
        if (item.value) {
          value += parseFloat(item.value)
        }
      })
      return value ? value : null
    },
    customerTotalArgs() {
      return this.load ? [this.load.load_finances.rate, this.load.load_finances.other_charges, this.load.load_finances.pds, this.load.load_finances.fsc_type, this.load.load_finances.fsc_value, this.load.load_finances.taxes, this.load.load_finances.vat_rate] : null
    }
  },
  methods: {
    handleLoadNotesClose(event) {
      this.notesOpened = false
      this.load.notes = event
    },
    haveUnuploadedFiles(load) {
      let condition = load?.checklist?.pod && load.checklist.rc && (load.status === 'completed' || load.status === 'invoiced')
      if(load.load_carriers.length === 1) {
        condition = condition && load.checklist.invoice && load.load_carriers[0].checklist.insurance_certificate && load.load_carriers[0].checklist.bank_details && (load.load_carriers[0].carrier.carrier_data.factoring_company_id ? (!!load.load_carriers[0].factoring_company_id) : true)
      } else if (load.load_carriers.length > 1) {
        condition = condition && load.checklist.invoices && load.load_carriers.every((carrier) => carrier.checklist.insurance_certificate && carrier.checklist.bank_details && (carrier.carrier.carrier_data.factoring_company_id ? (!!carrier.factoring_company_id) : true))
      }
      return condition
    },
    setCarrierFactoringCompany({index: carrierIndex, value}) {
      if (!value && this.load.load_carriers[carrierIndex].carrier.carrier_data.factoring_company && !this.load.load_carriers[carrierIndex].factoring_company) {
        this.load.load_carriers[carrierIndex].factoring_company_id = this.load.load_carriers[carrierIndex].carrier.carrier_data.factoring_company['id']
        this.load.load_carriers[carrierIndex].factoring_company = this.load.load_carriers[carrierIndex].carrier.carrier_data.factoring_company
      } else {
        this.load.load_carriers[carrierIndex].factoring_company_id = null
        this.load.load_carriers[carrierIndex].factoring_company = null
      }
    },
    openComments(value) {
      this.loadComments = value
    },
    updateOriginalLoad() {
      this.originalLoad = structuredClone(this.$store.state.loadInEdit);
    },
    rcView(id, carrier, customer, role) {
      this.save(false, false)
      setTimeout(() => {
        this.rc_url = config.apiUrl + '/report/' + id + '/' + carrier + '/' + customer
        this.rc_client = true
      }, 1000)
      this.rc_role = role
      this.rc_carrier = carrier
    },
    signLoad(load) {
      let signRequest = {
        type: this.rc_role
      }
      if (this.rc_carrier >= 0) {
        signRequest.carrier_index = this.rc_carrier
      }
      if(this.sign_directly === 0 && this.sign_custom_email) {
        signRequest.email = this.sign_custom_email
      }

      axios.post(config.apiUrl + '/api/load/' + load.id + '/sign', signRequest).then(() => {
        console.log('success sent')
        this.signSent = true
      }).catch(() => {
        this.signError = true
      })
    },
    downloadRC() {
      window.location.assign(config.pdfUrl + '/url?url=' + this.rc_url + '&name=Rate_Confirmation_' + encodeURIComponent('#' + this.load.id))
    },
    onCounterpartyChanged(value) {
      if (!value) {
        this.load.load_finances.location = null
        this.load.load_finances.customer_id = null
        this.load.load_finances.vat_rate = null
      } else {
        if (value.customer_data && value.customer_data.sales_representant_id) {
          axios.get(config.apiUrl + '/api/counterparty/' + value.customer_data.sales_representant_id)
            .then(response => {
              this.load.load_sale_representants = [response.data];
            })
        }
        this.load.load_finances.vat_rate = value.customer_data.vat_rate
      }
    },
    onLocationSelected(address) {
      if (typeof address === 'string') {
        this.load.load_finances.location = address
      } else {
        this.load.load_finances.location = [address.address1, address.city, address.state, address.country_code].filter(addressPart => {
          return !!addressPart
        }).join(', ')
        this.load.load_finances.location_parts = address
        if (address && address.location_data && address.location_coordinates) {
          this.load.load_finances.location_coordinates = address.location_coordinates
          this.load.load_finances.location_data = address.location_data
        }
      }
    },
    onLocationInput(address) {
      this.load.load_finances.location = [address.address1, address.city, address.state, address.country_code].filter(addressPart => {
        return !!addressPart
      }).join(', ')
    },
    closeLoad() {
      //if (this.unsaved === false) {
        this.$store.state.loadInEdit = null
      /*} else {
        this.loadUnsaved = true
      }*/
      this.rc_role = '';
      this.rc_carrier = null;
    },
    duplicate() {
      const newLoad = JSON.parse(JSON.stringify(this.$store.state.loadInEdit));
      newLoad.status = 'open';
      newLoad.id = null;
      this.$store.state.loadInEdit = newLoad;
      this.$store.state.loadCompleted = false;
      this.updateOriginalLoad();
    },
    async save(close, openFileManager = true) {
      this.loading = true;
      let method = 'post';
      let url = '/api/load';

      if (this.$store.state.loadInEdit.id) {
        method = 'patch';
        url = url + '/' + this.$store.state.loadInEdit.id.toString();
      }

      const valid = await this.$refs.loadForm.validate();

      if (!valid) {
        this.errorSnackbar = true;
        this.loading = false;
        this.unsaved = true;
        return;
      }

      axios[method](config.apiUrl + url, this.$store.state.loadInEdit)
        .then((response) => {
          if (close) {
            this.$store.state.loadInEdit = null;
            this.closeLoad();
          } else {
            this.$store.state.loadInEdit = response.data;
          }
          this.updateOriginalLoad();
          this.savedSnackbar = true;
          this.loading = false;
          this.unsaved = false;
          /*if ((this.load.status === 'completed' || this.load.status === 'invoiced') && openFileManager) {
            this.files = true
            this.load.load_carriers?.forEach(carrier => {
              if(carrier?.carrier?.carrier_data?.factoring_company) {
                carrier.factoring_company_id = carrier.carrier.carrier_data.factoring_company['id']
                carrier.factoring_company = carrier.carrier.carrier_data.factoring_company
              }
            })
          }*/
          this.$store.state.loadCompleted = true
          this.$emit('saved');
        })
        .catch(error => {
          alert(Object.values(error.response.data.errors).join('\r'))
          this.errorSnackbar = true;
          this.loading = false;
        })
    },
    openCustomerOtherCharges() {
      this.showCustomerOtherCharges = true
    }
  },
  watch: {
    'load.status': function (status) {
      if (this.$store.state.loadCompleted === true) {
        this.$store.state.loadCompleted = false
      }
    },
    customerTotalArgs: {
      handler(value) {
        if (!this.load) return
        if (!Array.isArray(this.load.load_finances.other_charges)) {
          this.load.load_finances.other_charges = [];
        }
        let otherCharges = 0;
        this.load.load_finances.other_charges.forEach(item => {
          otherCharges += parseFloat(item.value || 0)
        })
        let fsc = this.load.load_finances.fsc_type === 'amount' ? parseFloat(this.load.load_finances.fsc_value || 0) : (this.load.load_finances.rate * parseFloat(this.load.load_finances.fsc_value || 0) / 100)

        let total = parseFloat(this.load.load_finances.rate || 0) + parseFloat(this.load.load_finances.pds || 0) + otherCharges + fsc

        if (this.load.load_finances.taxes === 'Before taxes') {
          if (this.load.load_finances.vat_rate) {
            total += total * (this.load.load_finances.vat_rate.rate / 100);
          }
        }

        this.load.load_finances.total = total > 0 ? total : null
      },
      deep: true
    }
  }
}
</script>

<style scoped>
.load_layover {
  /*position: absolute;*/
  font-size: 1.5rem;
  height: -moz-available; /* 100% */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.load_layover--bg {
  position: relative;
  z-index: 9999;
  pointer-events: none;
}

.load_layover--bg_blurred {
  opacity: .2;
}</style>
