<template>
  <div class="header-menu">
    <ul>

      <li v-for="item in menu" v-if="item.admin === false">
        <router-link v-if="!restrictedWatch(item)" :to="item.link ? item.link : '#'">{{ item.title }}
          <v-icon v-if="item.sub">
            mdi-menu-down
          </v-icon>
        </router-link>
        <ul v-if="item.sub">
          <li v-for="sub in item.sub" v-if="sub.show.includes(currentRouteName) && !restrictedWatch(sub)">
            <router-link :to="sub.link">{{ sub.title }}
              <v-icon v-if="sub.sub">
                mdi-menu-right
              </v-icon>
            </router-link>
            <ul v-if="sub.sub">
              <li v-for="item in sub.sub" v-if="!restrictedWatch(item)">
                <router-link :to="item.link">{{ item.title }}</router-link>
              </li>
            </ul>
          </li>
        </ul>
      </li>
      <li v-else-if="item.admin === admin">
        <router-link :to="item.link ? item.link : '#'">{{ item.title }}
          <v-icon v-if="item.sub">
            mdi-menu-down
          </v-icon>
        </router-link>
        <ul v-if="item.sub">
          <li v-for="sub in item.sub" v-if="sub.show.includes(currentRouteName) && !restrictedWatch(sub)">
            <router-link :to="sub.link">{{ sub.title }}
              <v-icon v-if="sub.sub">
                mdi-menu-right
              </v-icon>
            </router-link>
            <ul v-if="sub.sub">
              <li v-for="item in sub.sub" v-if="!restrictedWatch(item)">
                <router-link :to="item.link">{{ item.title }}</router-link>
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
import config from '@/config';


export default {
  data() {
    return {
      url: config.appUrl,
      menu: [
        {
          title: 'Dashboard',
          link: '/',
          show: ['loads', 'home'],
          admin: false
        },
        {
          title: 'Users',
          link: '#',
          show: ['loads', 'home'],
          admin: false,
          sub: [
            {
              title: 'Internal',
              link: '/counterparty/users',
              show: ['loads', 'home'],
              restrict: ['sales_representant', 'dispatcher', 'customer_support', 'accounting'],
              admin: false,
            },
            {
              title: 'Teams',
              link: '/users/teams',
              show: ['loads', 'home'],
              restrict: ['sales_representant', 'dispatcher', 'customer_support'],
              admin: false,
            },
            {
              title: 'Customers',
              link: '/counterparty/customer',
              show: ['loads', 'home'],
              admin: false,
            },
            {
              title: 'Carriers',
              link: '/counterparty/carrier',
              show: ['loads', 'home'],
              admin: false,
            },
            {
              title: 'Shippers',
              link: '/counterparty/shipper',
              show: ['loads', 'home'],
              admin: false,
            },
            {
              title: 'Consignees',
              link: '/counterparty/consignee',
              show: ['loads', 'home'],
              admin: false,
            },
            {
              title: 'Customs brokers',
              link: '/counterparty/customs_broker',
              show: ['loads', 'home'],
              admin: false,
            },
            {
              title: 'Factoring companies',
              link: '/counterparty/factoring_company',
              show: ['loads', 'home'],
              admin: false,
            },
          ]
        },
        {
          title: 'Settings',
          link: '/settings',
          show: ['loads', 'home'],
          admin: true,
        }
      ]
    }
  },
  methods: {
    restrictedWatch(item) {
      if(item.hasOwnProperty('restrict')) {return this.$store.getters.role.some(role => {
        return item?.restrict?.includes(role)
      })} else return false
    },
  },
  computed: {
    currentRouteName() {
      return this.$route.meta.menu;
    },
    admin() {
      return this.$store.getters.role.includes('admin')
    }
  }
}

</script>
