<template>
  <div class="mt-2">
    <v-row align="center" dense class=" px-3">
      <v-col md="3">
        <blocking-layover :blocking-condition="restrictedEdit">
          <CounterpartySelect :clearable="true" @input="onCarrierChanged" v-model="carrier.carrier" :roles="['carrier']" @clear="carrier.carrier = null; carrier.carrier_id = null;" />
        </blocking-layover>
      </v-col>
      <v-col md="3">
        <EquipmentTypeSelect v-model="carrier.equipment_type"></EquipmentTypeSelect>
      </v-col>
      <v-col class="d-none" md="1">
        <CarrierFee :carrier="carrier"></CarrierFee>
      </v-col>
      <v-col class="d-none" :md="carrier.carrier && load.id && load.load_carriers[0].load_carrier_consignees[0].consignee && load.load_carriers[0].load_carrier_shippers[0].shipper ? '2' : '3'">
        <v-select class="mini" v-model="carrier.currency" hide-details="auto" :items="currency" label="Currency"
          outlined dense clearable>
        </v-select>
      </v-col>
      <v-col md="3">
        <v-text-field v-model="carrier.transport_number" label="Truck # / Trailer #" class="mini" hide-details="auto" outlined dense clearable></v-text-field>
      </v-col>
      <v-col md="3" v-if="carrier.carrier && load.id && load.load_carriers[0].load_carrier_consignees[0].consignee && load.load_carriers[0].load_carrier_shippers[0].shipper">
        <div class="d-flex">
            <v-btn @click="openLoadGps" block height="32" dark color="dark" >
              <v-icon color="white" small class="mr-3">mdi-map-marker-outline</v-icon>
              Tracking
            </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <Shippers :shippers="carrier.load_carrier_shippers" @duplicate-fields-changed="onDuplicateFieldsChange" />
    <v-divider></v-divider>
    <Consignee :consignees="carrier.load_carrier_consignees" />

    <v-snackbar
      v-model="successInsuranceRenew"
      :timeout="2000"
    >
      Carrier insurance data were updated! You can check it out on carrier profile.

      <template v-slot:action="{ attrs }">
        <v-btn
          color="success"
          text
          v-bind="attrs"
          @click="successInsuranceRenew = null"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      v-model="failInsuranceRenew"
      :timeout="2000"
    >
      There was an error when trying to renew carrier insurances. Please fill all necessary information about the carrier and try again

      <template v-slot:action="{ attrs }">
        <v-btn
          color="red"
          text
          v-bind="attrs"
          @click="failInsuranceRenew = null"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog :value="loadGps" @click:outside="loadGps = false" @close="loadGps = false" max-width="1400">
      <v-card>
        <v-row no-gutters>
          <v-col>
            <h3 class="p-3">
              Load tracking number

            </h3>
            <v-card-text class="mt-3">
              <v-row class="mb-1">
                <v-col>
                  <v-otp-input
                      length="8"
                      :value="token ? token.token : 'xxxxxxxx'"
                      readonly
                  />
                  <template v-if="token == null" class="pt-4">
                    <v-btn @click="generateToken" :loading="tokenLoading" color="primary">
                      GENERATE
                    </v-btn>
                  </template>
                  <template v-else>
                    Send tracking number to Carrier:
                    <v-spacer></v-spacer>
                    <v-btn color="success" small dark @click="copyToClipboard(token)">
                      <v-icon small>mdi-content-copy</v-icon> Copy
                    </v-btn>
                    <v-btn color="primary" small class="ml-3" dark @click="sendToCarrier('sms')">
                      Via SMS
                    </v-btn>
                    <v-btn color="primary" small dark class="ml-3" @click="sendToCarrier('email')">
                      Via Email
                    </v-btn>
                  </template>
                </v-col>
                <v-col class="col">
                  <v-select class="mb-2" x-small @change="updateCarrierStatus" label="Status" hide-details outlined v-model="carrier.status" :items="['not_started', 'in_progress', 'finished']">
                    <template v-slot:selection="{ item }">
                      {{ item.toUpperCase().replace('_', ' ') }}
                    </template>
                    <template v-slot:item="{ item }">
                      {{ item.toUpperCase().replace('_', ' ') }}
                    </template>
                  </v-select>
                  <v-row v-if="token !== null" class="mt-2">
                    <v-col>
                      <v-btn @click="fetchCarrierLocation" color="primary" dark block x-large>
                        GET CARRIER LOCATION
                      </v-btn>
                    </v-col>
                    <v-col>
                      <v-btn :href="'http://tracking.freightlinktracker.com/tracking/' + token['tracking_id']" target="_blank" block link x-large color="gray">
                        <v-icon>mdi-map-marker-right</v-icon>
                        Tracking URL
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <div style="width:300px">
              </div>
              <GmapMap
                  ref="map"
                  :center="carrierLocation ? carrierLocation : {lat:43.0064307, lng:-101.9585021}"
                  :zoom="5"
                  style="width: 100%; height: 500px"
              >
                <GmapMarker v-if="carrierLocation" :position="carrierLocation"></GmapMarker>
              </GmapMap>
            </v-card-text>
          </v-col>
          <v-col v-if="loadGps && token?.tracking_id && token?.fcm_token" class="chat-column" cols="3">
            <CarrierChat
                :dispatcher-id="this.$store.state.auth.user.counterparties[0].id"
                :display-name-dispatcher="this.$store.state.auth.user.counterparties[0].name"
                :carrier-id="carrier.carrier_id"
                :fcm-token-carrier="token.fcm_token"
                :display-name-carrier="carrier.carrier.name"
                :load-id="load.id"
            />
          </v-col>

        </v-row>

        <v-card-actions>
        </v-card-actions>
      </v-card>
    <v-snackbar
      v-model="copied"
      :timeout="2000"
    >
      Tracking number copied!

      <template v-slot:action="{ attrs }">
        <v-btn
          color="success"
          text
          v-bind="attrs"
          @click="copied = null"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
    </v-dialog>
    <v-snackbar
      v-model="errorSend"
      :timeout="2000"
    >
      {{ errorSendText }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="red"
          text
          v-bind="attrs"
          @click="errorSend = null"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Shippers from '../shippers/List.vue';
import Consignee from '../consignees/List.vue';
import CounterpartySelect from "@/components/counterparty/CounterpartySelect";
import EquipmentTypeSelect from "@/components/loads/carriers/EquipmentTypeSelect";
import BlockingLayover from '@/components/layout/BlockingLayover.vue';
import CarrierFee from "@/components/loads/carriers/CarrierFee.vue";
import loadLayoverMixin from '@/mixins/loadLayoverMixin';
import axios from "@/plugins/axios";
import config from "@/config";
import CarrierChat from "@/components/loads/carriers/CarrierChat.vue";
export default {
  mixins: [loadLayoverMixin],
  props: {
    carrier: {
      type: Object,
      required: true,
    },
    load: {
      type: Object,
      required: false
    }
  },
  components: {
    CarrierFee,
    EquipmentTypeSelect,
    CounterpartySelect,
    BlockingLayover,
    Shippers,
    Consignee,
    CarrierChat
  },
  computed: {
    restrictedEdit() {
      if(this.load.status === 'completed' && this.$store.getters.role.includes('customer_support')) {
        return false
      }
      return this.restrictLoadEdit(this.load)
    },
    loadInEdit() {
      return this.$store.state.loadInEdit;
    },
  },
  data() {
    return {
      equipment_type: [1, 2, 3, 4],
      currency: ['USD', 'CAD'],
      loadGps: false,
      errorSend: null,
      errorSendText: null,
      successInsuranceRenew: false,
      failInsuranceRenew: false,
      copied: false,
      token: null,
      tokenLoading: false,
      carrierLocation: null
    }
  },
  methods: {
    sendToCarrier(v) {
      this.errorSend = true
      if (v === 'sms') {
        this.errorSendText = 'SMS provider error'
      } else {
        this.errorSendText = 'Mail not connected'
      }
    },
    copyToClipboard(value) {
      navigator.clipboard.writeText(value.token)
      this.copied = true
    },
    openLoadGps () {
      axios.get(config.apiUrl + `/api/carrier-token/${this.load.id}/${this.carrier.carrier.id}`)
        .then((response) => {
            this.token = response.data
        })
        .catch(() => {
            this.token = null
        })
      this.loadGps = true
    },
    generateToken () {
        this.tokenLoading = true;

        axios.post(config.apiUrl + `/api/carrier-token/${this.load.id}/${this.carrier.carrier.id}`).then((response) => {
            this.token = response.data
            this.tokenLoading = false;
        })
    },
    fetchCarrierLocation () {
        axios.get(config.trackingApi + `/get-user-location/${this.token.tracking_id}`)
          .then((response) => {
              this.carrierLocation = response.data
          })
          .catch(() => {
              this.errorSend = true
              this.errorSendText = 'Carrier location not found'
          })
    },
    updateCarrierStatus (value) {
        axios.patch(config.apiUrl + `/api/carrier-status/${this.load.id}/${this.carrier.carrier.id}`, {status: value})
          .then(() => {
              this.errorSend = true
              this.errorSendText = 'Carrier status updated'
          })
    },
    onDuplicateFieldsChange(values, oldValues, index) {
      for (let prop in values) {
        if (
          typeof this.carrier.load_carrier_consignees[index] !== "undefined"
          &&
          (
            !(this.carrier.load_carrier_consignees[index][prop])
            ||
            this.carrier.load_carrier_consignees[index][prop] === oldValues[prop]
          )
        ) {
          this.carrier.load_carrier_consignees[index][prop] = values[prop]
        }
      }
    },
    onCarrierChanged (carrier, eventType) {
      if (carrier && carrier.carrier_data && carrier.carrier_data.default_currency) {
        this.carrier.currency = carrier.carrier_data.default_currency
      } else {
        this.carrier.currency = 'CAD'
      }
      if (eventType === 'change') {
        axios.get(config.apiUrl + '/api/counterparty/' + carrier.id + '/insurances')
          .then((response) => {
            if (response.data.expired.length || response.data.expired_soon.length) {
              axios.get(config.apiUrl + '/api/counterparty/' + carrier.id + '/insurances_renew').then((newResponse) => {
                if (newResponse.data.expired.length > 0) {
                    alert(carrier.name + ' - insurance expired');
                } else if (newResponse.data.expired_soon.length > 0) {
                    alert(carrier.name + ' - insurance expires in 14 days');
                } else {
                  this.successInsuranceRenew = true
                }
              }).catch(() => this.failInsuranceRenew = true)
            }
          })
      }
    }
  }
}

</script>
