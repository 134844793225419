<template>
  <v-container fluid>
    <v-breadcrumbs class="pt-0 pl-0 pb-2" :items="breads" divider="/"></v-breadcrumbs>
    <v-card color="primary" dark class=" mb-3">
      <v-card-text>
        <div class="d-flex w-100 align-items-center">
          <div class="page-header mr-5">
            <h4 class="my-0">Teams list</h4>
          </div>

          <v-spacer></v-spacer>
          <v-btn @click="addNew" small color="secondary" class="mr-3">+ Create new team</v-btn>
          <!-- <v-btn small light class="mr-5">
            <v-icon small class="mr-1">
              mdi-table
            </v-icon>
            Export .xls
          </v-btn>
          <v-btn small color="secondary">
            <v-icon small class="mr-1">
              mdi-printer
            </v-icon>
            Print
          </v-btn> -->
        </div>
      </v-card-text>
    </v-card>
    <v-row>
      <v-col md="3" v-for="item in teams">
        <v-card outlined>
          <v-card-title>
            {{ item.name }}
            <v-spacer></v-spacer>
            <v-btn class="mx-2" fab dark small color="primary" @click="editItem(item)">
              <v-icon dark>
                mdi-pencil
              </v-icon>
            </v-btn>
            <v-btn class="mx-2" fab dark small color="error" @click="removeItem(item)">
              <v-icon dark>
                mdi-close
              </v-icon>
            </v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <p><strong>Members:</strong></p>
            <v-menu bottom min-width="200px" rounded offset-y v-for="counterparty in item.counterparties">
              <template v-slot:activator="{ on }">
                <v-avatar v-on="on" color="secondary" class="mr-2" size="30">
                  <span class="white--text">{{ getInitials(counterparty.name) }}</span>
                </v-avatar>
              </template>
              <v-card>
                <v-list-item-content>
                  <div class="mx-auto d-flex px-3">
                    <div>
                      <v-avatar color="secondary" class="mr-3">
                        <span class="white--text text-h5">{{ getInitials(counterparty.name) }}</span>
                      </v-avatar>
                    </div>
                    <div>
                      <strong>{{ counterparty.name }}</strong>
                      <br>
                      <i v-for="role in counterparty.roles"> {{ role }}, </i>
                    </div>
                  </div>
                  <v-divider class="my-3"></v-divider>
                  <v-btn depressed rounded text @click="editUser(counterparty)">
                    Edit Account
                  </v-btn>
                </v-list-item-content>
              </v-card>
            </v-menu>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <TeamEdit v-if="$store.state.teams.inEdit" :team="$store.state.teams.inEdit"
      @saved="$store.dispatch('teams/getList');">
    </TeamEdit>
    <CounterpartyEdit @saved="getApiData()" v-if="$store.state.users.inEdit" :counterparty="$store.state.users.inEdit">
    </CounterpartyEdit>
  </v-container>
</template>

<script>

import CounterpartyEdit from "@/components/counterparty/CounterpartyEdit";
import TeamEdit from "@/components/team/TeamEdit";
export default {
  name: 'Teams',
  components: { TeamEdit, CounterpartyEdit },
  data() {
    return {
      breads: [
        {
          text: 'FREIGHTLINKTRACKER',
          disabled: false,
          href: '/',
        },
        {
          text: 'Teams',
          disabled: true,
          href: '#',
        },
      ],
      search: null,
      headers: [
        {
          text: '#',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        { text: 'Name', value: 'name' },
        { text: 'Email', value: 'email' },
        { text: 'Phone', value: 'phone' },
        { text: 'Role', value: 'role' },
        { text: 'Team', value: 'team' },
        { text: 'Status', value: 'status' },
        { text: 'Action', value: 'action' }
      ],
    }
  },
  created() {
    this.$store.dispatch('teams/getList');
  },
  mounted() {
    if(this.$store.getters.role.some(role => {
        return role === 'sales_representant' || role === 'dispatcher' || role === 'customer_support'
      })) {
        return this.$router.push("/")
    }
  },
  computed: {
    teams() {
      return this.$store.state.teams.list;
    }
  },
  methods: {
    editUser(user) {
      this.$store.state.users.inEdit = user
    },
    editItem(team) {
      this.$store.commit('teams/inEdit', team)
    },
    addNew() {
      this.$store.commit('teams/newInEdit');
    },
    getInitials(name) {
      const initial = name.split(' ')
      return `${initial[0].charAt(0)}${initial[1] ? initial[1].charAt(0) : ''}`
    },
    removeItem(item) {
      if (confirm("Remove team?")) {
        this.$store.dispatch('teams/remove', item)
          .then(() => {
            this.$store.dispatch('teams/getList');
          })
      }
    }
  }
}

</script>
