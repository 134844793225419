import Vue from "vue";
import VueRouter from "vue-router";
import Loads from "../apps/dispatch/Loads.vue";
import Teams from "../views/users/Teams.vue";
import GeneralUsers from "../views/users/List.vue"
import Accounting from "../views/accounting/Main.vue"
import InvoicesVue from "@/views/accounting/Invoices.vue";
import Dashboard from '@/views/Dashboard.vue'
import store from "@/store";
import CarrierNew from "@/views/settings/CarrierNew.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    meta: {
      title: 'FREIGHTLINKTRACKER',
      menu: null,
    },
    redirect () {
      return { path: "/dispatch/loads" }
    }
  },
  {
    path: "/carrier-new",
    name: "carrier_new",
    meta: {
      title: 'Become a Carrier',
      menu: null,
      authRequired: false
    },
    component: CarrierNew,
  },
  {
    path: "/login",
    name: "login",
    meta: {
      title: 'Login FREIGHTLINKTRACKER',
      menu: 'login',
    },
    component: function () {
      return import(/* webpackChunkName: "about" */ "../views/auth/Login.vue");
    },
  },
  {
    path: "/logout",
    name: "logout",
    meta: {
      title: 'Login FREIGHTLINKTRACKER',
      menu: 'login',
    },
    redirect () {
      store.state.auth.user = null
      store.state.auth.token = null
      localStorage.removeItem('user')
      localStorage.removeItem('token')
      return { path: '/login' }
    }
  },
  {
    path: "/dashboard",
    name: "home",
    meta: {
      title: 'ZEPTO Dashboard',
      menu: 'home',
      authRequired: true
    },
    component: Loads,
  },
  {
    path: "/dispatch/loads",
    name: "loads",
    meta: {
      title: 'Loads',
      menu: 'loads',
      authRequired: true
    },
    component: Loads,
  },
  {
    path: "/dispatch",
    name: "dispatch",
    meta: {
      title: 'Loads',
      menu: 'loads',
      authRequired: true
    },
    component: Loads,
  },
  {
    path: "/reports",
    name: "reports",
    meta: {
      title: 'Reports',
      menu: 'home',
      authRequired: true
    },
    component: function () {
      return import(/* webpackChunkName: "reports" */ "../views/reports/Reports.vue");
    }
  },
  {
    path: "/reports/general",
    name: "reports_general",
    meta: {
      title: 'Reports',
      menu: 'home',
      authRequired: true
    },
    component: function () {
      return import(/* webpackChunkName: "reports" */ "../views/reports/Reports.vue");
    }
  },
  {
    path: "/reports/salary",
    name: "reports_salary",
    meta: {
      title: 'Salary reports',
      menu: 'home',
      authRequired: true
    },
    component: function () {
      return import(/* webpackChunkName: "reports" */ "@/views/reports/salary/SalaryReportsList.vue");
    }
  },
  {
    path: "/reports/salaries",
    name: "salaries",
    meta: {
      title: 'Salaries',
      menu: 'home',
      authRequired: true
    },
    component: function () {
      return import(/* webpackChunkName: "reports" */ "../views/reports/Salaries.vue");
    }
  },
  {
    path: "/reports/employees",
    name: "employees",
    meta: {
      title: 'Employees report',
      menu: 'home',
      authRequired: true
    },
    component: function () {
      return import(/* webpackChunkName: "reports" */ "../views/reports/employees/Employees.vue");
    }
  },
  {
    path: "/reports/vendors",
    name: "vendors",
    meta: {
      title: 'Carriers',
      menu: 'home',
      authRequired: true
    },
    component: function () {
      return import(/* webpackChunkName: "reports" */ "../views/reports/Vendors.vue");
    }
  },
  {
    path: "/reports/customers",
    name: "customers",
    meta: {
      title: 'Customers',
      menu: 'home',
      authRequired: true
    },
    component: function () {
      return import(/* webpackChunkName: "reports" */ "../views/reports/Customers.vue");
    }
  },
  {
    path: "/reports/bills",
    name: "bills",
    meta: {
      title: 'Bills',
      menu: 'home',
      authRequired: true
    },
    component: function () {
      return import(/* webpackChunkName: "reports" */ "../views/reports/Bills.vue");
    }
  },
  {
    path: "/reports/payments",
    name: "payments",
    meta: {
      title: 'Payments',
      menu: 'home',
      authRequired: true
    },
    component: function () {
      return import(/* webpackChunkName: "reports" */ "../views/reports/Payments.vue");
    }
  },
  {
    path: "/counterparty/:role",
    name: "counterparty",
    meta: {
      title: 'Counterparties',
      menu: 'home',
      authRequired: true
    },
    component: GeneralUsers,
  },
  {
    path: "/users/teams",
    name: "teams",
    meta: {
      title: 'Teams',
      menu: 'home',
      authRequired: true
    },
    component: Teams,
  },
  {
    path: "/settings",
    name: "settings",
    meta: {
      title: 'Settings',
      menu: 'home',
      authRequired: true
    },
    component: function () {
      return import(/* webpackChunkName: "about" */ "../views/settings/Settings.vue");
    },
  },
  {
    path: "/accounting",
    name: "accounting",
    meta: {
      title: 'Accounting',
      menu: 'home',
      authRequired: true
    },
    component: Accounting,
  },
  {
    path: "/accounting/invoices",
    name: "invoices",
    meta: {
      title: 'Invoices',
      menu: 'home',
      authRequired: true
    },
    component: InvoicesVue,
  },
  {
    path: "/dashboard-beta",
    name: "dashboard",
    meta: {
      title: 'Dashboard',
      menu: 'home',
      authRequired: true
    },
    component: Dashboard,
  },
  {
    path: "/tools/import",
    name: "import_statement",
    meta: {
      title: 'Import statement',
      menu: 'home',
      authRequired: true
    },
    component: function () {
      return import("@/views/tools/ImportStatement.vue");
    }
  },
  {
    path: "/tools/import-statement",
    name: "import_statement_new",
    meta: {
      title: 'Import statement (NEW)',
      menu: 'home',
      authRequired: true
    },
    component: function () {
      return import("@/views/tools/ImportStatementNew.vue");
    }
  },
  {
    path: "/tools/import-transactions",
    name: "import_transactions",
    meta: {
      title: 'Import transactions',
      menu: 'home',
      authRequired: true
    },
    component: function () {
      return import("@/views/tools/ImportTransactions.vue");
    }
  },
  {
    path: "/transactions",
    name: "transactions",
    meta: {
      title: 'Transactions',
      menu: 'home',
      authRequired: true
    },
    component: function () {
      return import("@/views/Transactions.vue");
    }
  },
  {
    path: "/transactions/:batchId",
    name: "batchTransactions",
    meta: {
      title: 'Transactions',
      menu: 'home',
      authRequired: true
    },
    component: function () {
      return import("@/views/Transactions.vue");
    }
  },
  {
    path: "/tools/import-incoming-statement",
    name: "import_incoming_statement_new",
    meta: {
      title: 'Import incoming statement (NEW)',
      menu: 'home',
      authRequired: true
    },
    component: function () {
      return import("@/views/tools/ImportIncomingStatementNew.vue");
    }
  },
  { path: "*", name: '404', component: function () {
    return import(/* webpackChunkName: "about" */ "../views/404.vue");
  }, }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta['authRequired'] && (localStorage.getItem('user') === null || localStorage.getItem('token') === null)) {
    return next('/login')
  }
    const reportsRoutePattern = /^\/reports(\/.*)?$/ // blocking the whole reports section, any url

    if (to.path.match(reportsRoutePattern)) {
      if (JSON.parse(localStorage.getItem('user'))?.counterparties?.some(counterparty => {
        return counterparty?.roles?.some(role => role === 'dispatcher' || role === 'sales_representant')
      })) {
        next('/404')
      }
    }

  document.title = to.meta.title ? to.meta.title : "FREIGHTLINKTRACKER"
  next()
 })

export default router;
