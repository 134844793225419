<script>
import axios from "@/plugins/axios";
import config from "@/config";
import vueDropzone from "vue2-dropzone";

export default {
    name: "FilesList",
    components: {vueDropzone},
    props: {
        additionalData: {
            type: Object,
            required: false,
            default () {
              return {}
            }
        },
        additionalParams: {
            type: Object,
            required: false,
          default () {
            return {}
          }
        },
        colSize: {
            type: Number,
            required: false
        },
        roles: {
            type: Array,
            default () {
                return []
            }
        },
        entityType: {
            type: String,
            required: true
        },
        entityId: {
            type: Number,
            required: true
        }
    },
    created () {
        this.setFields()
        this.getFiles().then(() => {
            this.fillFields()
        })
    },
    data () {
        return {
            files: null,
            fields: {
                data: [],
                options: {
                    col: 3
                }
            },
            loading: false,
            allChecked: false,
            dropzoneOptions: {
                clickable: true,
                url: config.apiUrl + '/api/file/upload',
                thumbnailWidth: 150,
                maxFilesize: 99999999999,
                addRemoveLinks: true,
                paramName: 'files[]'
            },
        }
    },
    methods: {
        getFiles () {
            return axios.get(config.apiUrl + '/api/file/' + this.entityType + '/' + this.entityId, {
                params: Object.keys(this.additionalParams).length > 0 ? this.additionalParams: {}
            })
                .then((response) => {
                    if (typeof response.data === 'object') {
                        this.files= Object.values(response.data)
                    } else this.files = response.data
                })
        },
        setFields () {
            if (this.entityType === 'counterparty') {
                if (this.roles.includes('customer')) {
                    this.fields = {
                        data: [
                            {
                                type: 'agreement',
                                name: 'Agreement',
                                required: true,
                                uploaded: false,
                                files: []
                            },
                            {
                                type: 'w89',
                                name: 'W8-9',
                                required: true,
                                uploaded: false,
                                files: []
                            }
                        ],
                        options: {
                            col: this.colSize ?? 6
                        }
                    }
                }
                if (this.roles.includes('load_carrier_single')) {
                    this.fields = {
                        data: [
                            {
                                type: 'insurance_certificate',
                                name: 'Insurance certificate',
                                required: true,
                                uploaded: false,
                                files: []
                            },
                            {
                                type: 'bank_details',
                                name: 'Bank details',
                                required: true,
                                uploaded: false,
                                files: []
                            },
                            {
                                type: 'w8_9',
                                name: 'W8-9',
                                required: true,
                                uploaded: false,
                                files: []
                            }
                        ],
                        options: {
                            col: this.colSize ?? 4
                        }
                    }
                }
                if (this.roles.includes('load_carrier_multiple')) {
                    this.fields = {
                        data: [
                            {
                                type: 'insurance_certificate',
                                name: 'Insurance certificate',
                                required: true,
                                uploaded: false,
                                files: []
                            },
                            {
                                type: 'bank_details',
                                name: 'Bank details',
                                required: true,
                                uploaded: false,
                                files: []
                            },
                            {
                                type: 'w8_9',
                                name: 'W8-9',
                                required: true,
                                uploaded: false,
                                files: []
                            },
                            {
                                type: 'invoice',
                                name: 'Invoice',
                                required: true,
                                uploaded: false,
                                files: []
                            },
                        ],
                        options: {
                            col: this.colSize ?? 4
                        }
                    }
                }
                if (this.roles.includes('carrier')) {
                    this.fields = {
                        data: [
                            {
                                type: 'insurance_certificate',
                                name: 'Insurance certificate',
                                required: true,
                                uploaded: false,
                                files: []
                            },
                            {
                                type: 'bank_details',
                                name: 'Bank details',
                                required: true,
                                uploaded: false,
                                files: []
                            },
                            {
                                type: 'mc_nir_cvor',
                                name: 'MC/NIR/CVOR',
                                required: true,
                                uploaded: false,
                                files: []
                            },
                            {
                                type: 'w8_9',
                                name: 'W8-9',
                                required: true,
                                uploaded: false,
                                files: []
                            },
                            {
                                type: 'agreement',
                                name: 'Agreement',
                                required: true,
                                uploaded: false,
                                files: []
                            },
                            {
                                type: 'noa',
                                name: 'NOA',
                                required: false,
                                uploaded: false,
                                files: []
                            },
                        ],
                        options: {
                            col: this.colSize ?? 4
                        }
                    }
                }
            } else if (this.entityType === 'bill') {
                this.fields = {
                    data: [
                        {
                            type: 'pod',
                            name: 'POD',
                            required: true,
                            uploaded: false,
                            files: []
                        },
                        {
                            type: 'rate_confirmation',
                            name: 'RC',
                            required: true,
                            uploaded: false,
                            files: []
                        },
                        {
                            type: 'mc_nir_cvor',
                            name: 'MC/DOT',
                            required: true,
                            uploaded: false,
                            files: []
                        },
                        {
                            type: 'carrier_invoice',
                            name: 'Invoice',
                            required: true,
                            uploaded: false,
                            files: []
                        },
                        {
                            type: 'insurance_certificate',
                            name: 'Insurance',
                            required: true,
                            uploaded: false,
                            files: []
                        }
                    ],
                    options: {
                        col: this.colSize ?? 2
                    }
                }
            } else if (this.entityType === 'load') {
                if (this.additionalData.hasOwnProperty("load_file_type")) {
                    if (this.additionalData.load_file_type === 'single') {
                        this.fields = {
                            data: [
                                {
                                    type: 'pod',
                                    name: 'POD',
                                    required: true,
                                    uploaded: false,
                                    files: []
                                },
                                {
                                    type: 'rate_confirmation',
                                    name: 'RC',
                                    required: true,
                                    uploaded: false,
                                    files: []
                                },
                                {
                                    type: 'invoice',
                                    name: 'Invoice',
                                    required: true,
                                    uploaded: false,
                                    files: []
                                }
                            ],
                            options: {
                                col: this.colSize ?? 2
                            }
                        }
                    } else if (this.additionalData.load_file_type === 'multiple') {
                        this.fields = {
                            data: [
                                {
                                    type: 'pod',
                                    name: 'POD',
                                    required: true,
                                    uploaded: false,
                                    files: []
                                },
                                {
                                    type: 'rate_confirmation',
                                    name: 'RC',
                                    required: true,
                                    uploaded: false,
                                    files: []
                                }
                            ],
                            options: {
                                col: this.colSize ?? 2
                            }
                        }
                    }
                } else {
                    this.fields = {
                        data: [
                            {
                                type: 'pod',
                                name: 'POD',
                                required: true,
                                uploaded: false,
                                files: []
                            },
                            {
                                type: 'rate_confirmation',
                                name: 'RC',
                                required: true,
                                uploaded: false,
                                files: []
                            },
                            {
                                type: 'invoice',
                                name: 'Invoice',
                                required: true,
                                uploaded: false,
                                files: []
                            }
                        ],
                        options: {
                            col: this.colSize ?? 2
                        }
                    }
                }
            }
        },
        fillFields () {
            this.fields.data.forEach((field) => {
                field.files = []
                field.uploaded = false
                this.files.forEach((file) => {
                    if (field.type === file.custom_properties.file_type) {
                        field.files.push(file)
                        field.uploaded = true
                    }
                })
            })
        },
        emitStatus () {
            if (this.filtered.length > 0) {
                this.$emit('changeObject', this.filtered)
            }
        },
        setStatus (idx) {
            this.fields.data[idx].uploaded = true
        },
        remove (idx) {
            if (confirm("Do you really want to delete?")) {
                this.fields.data[idx].uploaded = false
            }
        },
        sendingEvent (file, xhr, formData, fileType) {
            formData.append('entity_id', this.entityId);
            formData.append('entity_type', this.entityType);
            formData.append('file_type', fileType);
            if(this.additionalParams.hasOwnProperty('load_id')) {
                formData.append('load_id', this.additionalParams.load_id)
            }
        },
        uploadCompleted (event, fileType) {
            this.$emit('upload', event, fileType)
            this.loading = false
            this.getFiles().then(() => {
                this.fillFields()
            })
        },
        downloadFile (file) {
            window.open(config.apiUrl + '/api/media/' + file.uuid)
        },
        removeFile (file) {
            if (confirm('Do you want to remove file?')) {
                axios.delete(config.apiUrl + '/api/file/' + this.entityType + '/' + this.entityId + '/' + file.uuid)
                    .then((response) => {
                        this.getFiles().then(() => {
                            this.fillFields()
                        })
                    })
            }
        }
    }
}
</script>

<template>
    <div class="drop-zone">
        <v-row v-if="files !== null">
            <v-col :md="fields.options.col" v-for="(item, idx) in fields.data" :key="'vdz-' + idx">
                <vue-dropzone
                        ref="myVueDropzone"
                        :id="'dropzone-' + idx"
                        :options="dropzoneOptions"
                        :useCustomSlot="true"
                        @vdropzone-file-added="setStatus(idx)"
                        @vdropzone-upload-progress="loading = true"
                        @vdropzone-complete="uploadCompleted($event, item.type)"
                        @vdropzone-sending="(file, xhr, formData) => sendingEvent(file, xhr, formData, item.type)"
                        :clickable="!item.uploaded"
                >
                    <div :class="item.required ? 'drop-item text-center mandatory' : 'drop-item text-center'" :style="item.uploaded ? 'border-color: green; border-style: solid;' : ''">
                        <h5>{{ item.name }}</h5>
                        <v-icon color="green lighten-1" v-if="item.uploaded">mdi-check-circle</v-icon>
                        <v-icon color="grey lighten-1" v-else>mdi-cloud-upload</v-icon>
                        <template v-if="item.uploaded && typeof item.files[0] !== 'undefined'">
                            <v-btn text x-small class="mt-1" @click.stop="downloadFile(item.files[0])" >Download file</v-btn>
                            <v-btn text x-small class="mt-1" color="red" @click.stop="removeFile(item.files[0])">
                                <v-icon x-small>mdi-close</v-icon>
                            </v-btn>
                        </template>
                    </div>
                </vue-dropzone>
            </v-col>
        </v-row>
        <v-skeleton-loader
            v-else
            class="mx-auto"
            max-width="100%"
            type="card"
        ></v-skeleton-loader>
    </div>
</template>

<style scoped>

</style>
