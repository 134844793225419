<template>
  <v-container fluid>
    <v-breadcrumbs class="pt-0 pl-0 pb-2" :items="breads" divider="/"></v-breadcrumbs>

    <v-card color="primary" dark class=" mb-3">
      <v-card-text>
        <div class="d-flex w-100 align-items-center">
          <div class="page-header mr-5">
            <h4 class="my-0">{{ roleName }} list</h4>
          </div>
          <v-btn small @click="addNew" color="green" class="mr-3">Add new</v-btn>
          <v-text-field class="mini-search mr-5" flat solo v-model="search" @change="onSearch" append-icon="mdi-magnify"
            label="Search" dense hide-details light>
          </v-text-field>
          <v-select v-if="this.$route.params.role === 'carrier'" v-model="insurance_expired_state" @change="onInsuranceExpirationChange" class="mini-search mr-5" flat solo dense hide-details light label="Insurance expiration" :items="['All insurances', 'Expired', 'Not expired']"></v-select>
          <v-spacer></v-spacer>
          <strong class="mx-5">Total: {{ gridData.total }}</strong>
          <v-btn small light class="mr-5">
            <v-icon small class="mr-1">
              mdi-table
            </v-icon>
            Export .xls
          </v-btn>
          <v-btn small color="secondary">
            <v-icon small class="mr-1">
              mdi-printer
            </v-icon>
            Print
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
    <v-card outlined>
      <v-card-text>
        <v-data-table :headers="headers" :items="gridData.data" item-key="id" :server-items-length="gridData.total"
          :loading="loading" hide-default-footer @page-count="gridData.last_page = $event" :items-per-page="20"
          :page.sync="page" @update:options="optionsUpdated" @click:row="editItem($event)" dense>

          <template v-slot:item.avatar="{ item }">
            <v-avatar color="secondary" size="30" rounded>
              <v-img :src="item.avatar" v-if="item.avatar"></v-img>
              <span class="white--text" v-else>--</span>
            </v-avatar>
          </template>
          <template v-slot:item.name="{ item }">
            <strong>
              {{ item.name }}
            </strong>
          </template>
          <template v-slot:item.payment_terms="{ item }">
            {{ item?.customer_data?.payment_term?.name ? item.customer_data.payment_term.name + "/" + item.customer_data.payment_term.days + " days" : "" }}
          </template>
          <template v-slot:item.email="{ item }">
            <div v-if="item.user">
              {{ item.user.email ?? '-' }}
            </div>
            <div v-else>
              <CounterpartyContact :counterparty="item" contact-type="email"></CounterpartyContact>
            </div>
          </template>
          <template v-slot:item.phone="{ item }">
            <CounterpartyContact :counterparty="item" contact-type="default"></CounterpartyContact>
          </template>
          <template v-slot:item.address="{ item }">
            <div class="text-address" v-if="item.counterparty_addresses.length >= 2"
              v-for="(address, idx) in item.counterparty_addresses">
              <span>
                #{{ idx + 1 }}:
                {{ address.postcode ? address.postcode + ', ' : '' }}
                {{ address.address1 ? address.address1 + ', ' : '' }}
                {{ address.city ? address.city + ', ' : '' }}
                {{ address.state ? address.state + ', ' : '' }}
                {{ address.country_code ? address.country_code + '' : '' }}
              </span>
            </div>
            <div class="text-address" v-else>
              {{ item.counterparty_addresses[0].postcode ? item.counterparty_addresses[0].postcode + ', ' : '' }}
              {{ item.counterparty_addresses[0].address1 ? item.counterparty_addresses[0].address1 + ', ' : '' }}
              {{ item.counterparty_addresses[0].city ? item.counterparty_addresses[0].city + ', ' : '' }}
              {{ item.counterparty_addresses[0].state ? item.counterparty_addresses[0].state + ', ' : '' }}
              {{ item.counterparty_addresses[0].country_code ? item.counterparty_addresses[0].country_code + '' : '' }}
            </div>
          </template>
          <template v-slot:item.team="{ item }">
            <v-chip v-if="item.teams" small class="mr-2" color="secondary" v-for="team in item.teams" label>
              {{ team.name }}
            </v-chip>
            <span v-else>-</span>
          </template>
          <template v-slot:item.safer_watch="{ item }">
            <div class="d-flex">
            <div class="d-flex align-items-center mr-3" @click.stop="openSw(item)" style="cursor:pointer">
              <span class="text-default font-weight-bold subtitle-1">S</span>
              <span class="text-success font-weight-bold subtitle-1">W</span>
            </div>
            <v-btn @click.stop="$store.dispatch('carrierHistory/carrierHistory', { carrier_id: item.id, per_page: 1000 })" small text>History</v-btn>
            </div>
          </template>
          <template v-slot:item.status="{ item }">
            <v-icon small v-if="item.status === 'active'" color="green">mdi-checkbox-blank-circle</v-icon>
            <v-icon small v-else color="red">mdi-close-circle</v-icon>
          </template>
          <template v-slot:item.credit_limit="{item}">
            <v-progress-linear background-color="red accent-2" dark color="success" reverse height="20"
              :value="calculatePercentage(item?.customer_data?.credit_limit_balance ?? 0, item?.customer_data?.credit_limit ?? 0)">

              <template v-slot:default="{ value }">
                <small>{{ item?.customer_data?.credit_limit ?? 0 }}</small>
              </template>
            </v-progress-linear>
          </template>
          <template v-slot:item.action="{ item }">
            <v-btn color="primary" @click="editItem(item)" class="mr-3" small text>
              EDIT
            </v-btn>
            <v-btn color="error" @click.stop="confirmDialog(item.id)" small text>
              DELETE
            </v-btn>
          </template>
          <template v-slot:item.roles="{ item }">
            <CounterpartyRolesColumn :counterparty="item"></CounterpartyRolesColumn>
          </template>
        </v-data-table>
        <div class="text-center pt-2">
        </div>
      </v-card-text>
    </v-card>
    <v-pagination class="my-3" v-model="page" :length="gridData.last_page" :total-visible="10"></v-pagination>
    <v-dialog v-model="swDialog" width="1200px" @click:outside="selectedItem === null">
      <sw :close.sync="swDialog" :carrier-data="selectedItem"></sw>
    </v-dialog>
    <CounterpartyEdit @saved="getApiData()" v-if="$store.state.users.inEdit" :counterparty.sync="$store.state.users.inEdit" />
    <v-dialog :value="dialog" persistent max-width="400">
      <v-card>
        <v-card-title class="text-h5">
          Confirm
        </v-card-title>
        <v-card-text>Are you sure you want to delete this record?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" dark @click="dialog = false" small>
            No
          </v-btn>
          <v-btn color="green darken-1" dark small @click="deleteItem(confirmDialog)">
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>

import CounterpartyContact from "@/components/counterparty/CounterpartyContact";
import CounterpartyRolesColumn from "@/components/counterparty/CounterpartyRolesColumn";
import CounterpartyEdit from "@/components/counterparty/CounterpartyEdit";
import CounterpartySaferWatch from "@/components/counterparty/CounterpartySaferWatch";

import calculationMixin from "@/mixins/calculationMixin";

import axios from "@/plugins/axios";
import config from "@/config";
export default {
  name: 'UsersList',
  mixins: [
    calculationMixin
  ],
  components: { CounterpartyEdit, CounterpartyRolesColumn, CounterpartyContact, CounterpartySaferWatch },
  data() {
    return {
      search: null,
      insurance_expired_state: 'All',
      insurance_expired: null,
      swDialog: false,
      gridOptions: {},
      loading: false,
      selectedItem: null,
      page: 1,
      dialog: null
    }
  },
  mounted() {
    this.restrictRoute()
  },
  watch: {
    $route(to, from) {
      this.restrictRoute()
      if (to.params.role === 'users') {
        this.gridOptions['role'] = this.$options.filterRoles
        if (!this.$store.getters.role.includes('admin') || !this.$store.getters.role.includes('accounting')) {
          this.$router.push({ name: '404' })
        }
      } else {
        this.gridOptions['role'] = [to.params.role]
      }
      this.getApiData()
    }
  },
  beforeCreate() {
    if (this.$route.params.role === 'users' && (!this.$store.getters.role.includes('admin') && !this.$store.getters.role.includes('accounting'))) {
      this.$router.push({ name: '404' })
    }
  },
  computed: {
    breads() {
      return [
        {
          text: 'FREIGHTLINKTRACKER',
          disabled: false,
          href: '/',
        },
        {
          text: this.roleName,
          disabled: true,
        },
      ]
    },
    headers() {
      if (this.$route.params.role === 'users') {
        return [
          {
            text: '#',
            align: 'start',
            sortable: true,
            value: 'id',
          },
          { text: 'Avatar', value: 'avatar' },
          { text: 'Name', value: 'name' },
          { text: 'Email', value: 'email' },
          { text: 'Phone', value: 'phone' },
          { text: 'Role', value: 'roles' },
          { text: 'Team', value: 'team' },
          { text: 'Status', value: 'status' },
          { text: 'Action', value: 'action' }
        ]
      } else if (this.$route.params.role === 'customer') {
        return [
          {
            text: '#',
            align: 'start',
            sortable: true,
            value: 'id',
          },
          { text: 'Name', value: 'name' },
          { text: 'Email', value: 'email' },
          { text: 'Address', value: 'address' },
          { text: 'Credit Limit / DTP', value: 'credit_limit' },
          { text: 'Payment terms / Avg.', value: 'payment_terms', sortable: false },
          { text: 'Status', value: 'status' },
          { text: 'Role', value: 'roles' }
        ]
      } else if (this.$route.params.role === 'carrier') {
        return [
          {
            text: '#',
            align: 'start',
            sortable: true,
            value: 'id',
          },
          { text: 'Name', value: 'name' },
          { text: 'Email', value: 'email' },
          { text: 'Address', value: 'address' },
          { text: 'Role', value: 'roles' },
          { text: 'Safer Watch', value: 'safer_watch' },
          { text: 'Status', value: 'status' },
          { text: 'Action', value: 'action' }
        ]
      } else {
        return [
          {
            text: '#',
            align: 'start',
            sortable: true,
            value: 'id',
          },
          { text: 'Name', value: 'name' },
          { text: 'Phone', value: 'phone' },
          { text: 'Address', value: 'address' },
          { text: 'Action', value: 'action' }
        ]
      }
    },
    roleName() {
      return this.$route.params.role[0].toUpperCase() + this.$route.params.role.slice(1);
    },
    gridData() {

      this.loading = false
      return this.$store.state.users.data
    }
  },
  options: {
    filterRoles: ['admin', 'staff', 'sales_representant', 'dispatcher', 'accounting']
  },
  methods: {
    restrictRoute() {
      if(this.$store.getters.role.some(role => {
          return role === 'dispatcher'
        }) && (this.$route?.params?.role === 'users')) {
          return this.$router.push("/")
      }
    },
    openSw(item) {
      this.selectedItem = item
      this.swDialog = true
    },
    getApiData() {
      this.$store.state.users.data.data = []
      this.loading = true
      return this.$store.dispatch('users/getList', this.apiParams())
    },
    optionsUpdated(options) {
      this.gridOptions = options
      if (this.$route.params.role === 'users') {
        this.gridOptions['role'] = ['admin', 'staff', 'sales_representant', 'dispatcher', 'accounting', 'customer_support']
      } else {
        this.gridOptions['role'] = [this.$route.params.role]
      }
      this.getApiData()
    },
    onSearch(search) {
      this.search = search
      this.gridOptions['page'] = 1
      this.getApiData()
    },
    onInsuranceExpirationChange (value) {
        if (value === 'All') {
            this.insurance_expired = null
        } else if (value === 'Expired') {
            this.insurance_expired = 1
        } else if (value === 'Not expired') {
            this.insurance_expired = 0
        }
        this.gridOptions['page'] = 1
        this.getApiData()
    },
    apiParams() {
      const params = this.gridOptions;
      params['search'] = this.search
      params['insurance_expired'] = this.insurance_expired
      return params
    },
    editItem(user) {
      this.$store.state.users.inEdit = user
    },
    confirmDialog(id) {
      this.dialog = id
    },
    deleteItem() {
      axios.delete(config.apiUrl + '/api/counterparty/' + this.dialog).then((response) => {
        this.getApiData()
        this.dialog = null
      })
    },
    addNew() {
      this.$store.commit('users/newInEdit', { roles: [this.$route.params.role] });
    }
  }
}

</script>
