<template>
  <div v-if="loaded">
    <div>
      <v-row dense>
        <v-col md="3">
          <BarMarkers :count="true" :name="'YTD Revenue'" :data="data.revenue" />
        </v-col>
        <v-col md="3">
          <BarNegative :count="true" :name="'Revenue Target'" :data="data.target" />
        </v-col>
        <v-col md="3">
          <BarNegative :count="true" :name="'YTD Net Profit'" :data="data.profit" />
        </v-col>
        <v-col md="3">
          <BarNegative :count="true" :name="'Net Profit Target'" :data="data.profit_target" />
        </v-col>
      </v-row>
    </div>
    <div>
      <v-row dense>
        <v-col md="4">
          <BarMarkers :height="200" :set-colors="['#000']" :count="false" :name="'YTD teams volume'" :data="data.teams_volume" />
        </v-col>
        <v-col md="4">
          <BarMarkers :height="200" :count="false" :name="'YTD teams revenue'" :data="data.teams_revenue" />
        </v-col>
        <v-col md="4">
          <BarMarkers :height="200" :count="false" :name="'YTD teams profit'" :data="data.teams_profit" />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import BarMarkers from './widgets/BarMarkers.vue'
import BarNegative from './widgets/BarNegative.vue'
import axios from "@/plugins/axios";
import config from "@/config";
export default {
  name: "DashboardSales",
  components: {
    apexchart: VueApexCharts,
    BarMarkers,
    BarNegative
},
  props: {
    data: {
      type: Object,
      required: true
    },
    filters: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loaded: false
    }
  },
  mounted() {
    this.data.revenue.series[0]['data'] = [];
    //this.data.target.series = []
    this.data.profit.series[0]['data'] = [];
    //this.data.profit_target.series = []
    this.loaded = false;
    this.fetchApiData();
  },
  methods: {
    fetchApiData() {
        this.$emit('loading', true)
      axios.get(config.apiUrl + '/api/reports/sales-totals', {
        params: this.filters
      }).then((response) => {
        this.data.revenue.series[0]['data'] = response.data.revenue.map(i => {
          return {
            x: i['MONTH'],
            y: i['REVENUE'],
            goals: [
              {
                name: 'Target',
                value: 0,
                strokeDashArray: 0,
                strokeColor: '#000'
              }
            ]
          }
        })
        this.data.revenue.count = response.data['revenueTotal']

        this.data.profit.series[0]['data'] = response.data.profit.map(i => {
          return {
            x: i['MONTH'],
            y: i['PROFIT'],
            goals: [
              {
                name: 'Target',
                value: 0,
                strokeDashArray: 0,
                strokeColor: '#000'
              }
            ]
          }
        })
        this.data.profit.count = response.data['profitTotal'].toFixed()

        this.data.teams_revenue.series[0]['data'] = response.data.revenueByTeam.filter(i => i['TEAM'] !== null).map(i => {
          return {
            x: i['TEAM'],
            y: i['REVENUE'],
            goals: [
              {
                name: 'Target',
                value: 0,
                strokeDashArray: 0,
                strokeColor: '#000'
              }
            ]
          }
        })

        this.data.teams_profit.series[0]['data'] = response.data.profitByTeam.filter(i => i['TEAM'] !== null).map(i => {
          return {
            x: i['TEAM'],
            y: i['PROFIT'],
            goals: [
              {
                name: 'Target',
                value: 0,
                strokeDashArray: 0,
                strokeColor: '#000'
              }
            ]
          }
        })

        this.data.teams_volume.series[0]['data'] = response.data.loadsByTeam.filter(i => i['TEAM_ID'] !== null).map(i => {
          return {
            x: i['TEAM_ID'],
            y: i['COUNT'],
            goals: [
              {
                name: 'Target',
                value: 0,
                strokeDashArray: 0,
                strokeColor: '#000'
              }
            ]
          }
        })

        this.loaded = true
        this.$emit('loading', false)
      })
    }
  }
}
</script>
