<template>
  <div>
    <v-card flat outlined dense>
      <v-card-title v-if="name">{{ name }}</v-card-title>
      <v-card-text>
        <div class="d-flex justify-content-between">
          <h3>{{ count }}</h3>
          <div>
            <v-btn color="red" text>{{ subjectCount + ' ' + type }}</v-btn>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: null
    },
    count: {
      type: Number,
      default: 0
    },
    subjectCount: {
      type: Number,
      default: 0
    },
    type: {
      type: String,
      default: ''
    },
    height: {
      type: Number,
      default: 100
    }
  },
  data () {
    return {

    }
  }
}
</script>