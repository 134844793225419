<template>
    <blocking-layover component="tr" style="display: table-row;position: unset" :blocking-condition="restrictedEdit">
      <td>
        <v-text-field v-model="item.nr" class="mini" dense hide-details outlined></v-text-field>
      </td>
      <td>
        <v-select v-model="item.thirdParty" class="mini" dense hide-details outlined></v-select>
      </td>
      <td style="max-width: 230px" >
        <v-select v-model="item.carrier" :items="carriers" return-object item-text="carrier.name" class="mini" dense hide-details outlined></v-select>
      </td>
      <td style="width: 105px">
        <v-select v-model="item.freightCharges" :items="freightCharges" class="mini" dense hide-details outlined></v-select>
      </td>
      <td style="max-width: 180px">
        <v-select v-model="item.origin" :items="origins" class="mini" dense hide-details outlined></v-select>
      </td>
      <td style="max-width: 180px">
        <v-select v-model="item.destination" :items="destinations" class="mini" dense hide-details outlined></v-select>
      </td>
      <td>
        <v-text-field v-model="item.emergency" class="mini" dense hide-details outlined></v-text-field>
      </td>
      <td style="width: 95px">
        <v-select v-model="item.codType" :items="['Prepaid', 'Collect']" class="mini" dense hide-details outlined></v-select>
      </td>
      <td>
        <v-text-field v-model.number="item.codAmount" class="mini" dense hide-details outlined></v-text-field>
      </td>
      <td>
        <v-text-field v-model.number="item.value" class="mini" dense hide-details outlined></v-text-field>
      </td>
      <td>
        <v-text-field v-model="item.notes" class="mini" dense hide-details outlined></v-text-field>
      </td>
      <td>
        <v-dialog
            max-width="800"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn style="pointer-events: all;" x-small outlined height="24" v-bind="attrs" v-on="on">ITEMS ({{ item.items.length }})</v-btn>
          </template>
          <v-toolbar flat dense dark color="primary">
            <v-toolbar-title tag="span" class="subtitle-1">Items</v-toolbar-title>
          </v-toolbar>
          <v-card flat light class="p-3">
            <BillOfLandingLineItems :items="item.items"></BillOfLandingLineItems>
          </v-card>
        </v-dialog>
      </td>
    
    <td style="width: 105px">
      <v-btn style="pointer-events:all;" x-small @click="print" height="24" :loading="printInProgress">
        <v-icon>mdi-printer</v-icon>
      </v-btn>
      <v-btn x-small @click="remove" height="24" class="ml-3">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </td>
  </blocking-layover>
</template>

<script>
import BillOfLandingLineItems from "@/components/loads/bol/BillOfLandingLineItems";
import BlockingLayover from "@/components/layout/BlockingLayover.vue";
import axios from "@/plugins/axios";
import config from "@/config";
import loadLayoverMixin from "@/mixins/loadLayoverMixin";
export default {
  name: "BillOfLandingLine",
  mixins: [loadLayoverMixin],
  components: {BillOfLandingLineItems, BlockingLayover},
  props: {
    item: {
      type: Object,
      required: true
    },
    load: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: false
    },
    companyCarrier: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      freightCharges: ['Prepaid', 'Collect', '3rd Party'],
      printInProgress: false
    }
  },
  mounted () {
    this.item.nr = this.index+1
  },
  computed: {
    restrictedEdit() {
      return this.restrictLoadEdit(this.load)
    },
    carriers() {
      if (!this.load.load_carriers[0].carrier) {
        return []
      } else if(this.companyCarrier) {
        let item = JSON.parse(JSON.stringify(this.companyCarrier))
        item.carrier = this.companyCarrier
        item.load_carrier_shippers = this.load.load_carriers[0].load_carrier_shippers
        item.load_carrier_consignees = this.load.load_carriers[0].load_carrier_consignees
          return [item].concat(this.load.load_carriers)
      } else return this.load.load_carriers
    },
    origins() {
      if (this.item.carrier) {
        return this.item.carrier.load_carrier_shippers.map(shipper => shipper.location)
      } else {
        const locations = []
         this.load.load_carriers.forEach((carrier) => {
          carrier.load_carrier_shippers.forEach((shipper) => {
            locations.push(shipper.location)
          })
        })
        return locations
      }
    },
    destinations() {
      if (this.item.carrier) {
        return this.item.carrier.load_carrier_consignees.map(consignee => consignee.location)
      } else {

        const locations = []
         this.load.load_carriers.forEach((carrier) => {
          carrier.load_carrier_consignees.forEach((consignee) => {
            locations.push(consignee.location)
          })
        })
        return locations
      }
    }
  },
  methods: {
    print() {
      this.printInProgress = true;
      axios.post(config.apiUrl + '/api/load/' + this.load.id + '/bol', {
        item: this.item
      })
          .then(response => {
            window.location.assign(config.pdfUrl + '/url?url=' + config.apiUrl + '/bol/' + this.load.id + '/' + this.index + '&name=Bill_Of_Lading_Load' + encodeURIComponent('#' + this.load.id) + (this.load.wo ? '-WO' + encodeURIComponent('#' + this.load.wo) : ''))
          })
          .catch(error => {

          })
          .finally(() => {
            this.printInProgress = false
          })
    },
    remove() {
      this.$emit('remove', this.item)
    }
  }
}
</script>

<style scoped>

</style>
