<template>
  <div v-if="id && type">
    <vue-dropzone v-if="show" ref="myVueDropzone" id="dropzone" :options="dropzoneOptions" :useCustomSlot="true"
      @vdropzone-success="getFiles()" @vdropzone-upload-progress="loading = true" @vdropzone-complete="loading = false">
      <v-data-table style="pointer-events:all" v-model="selectedFiles" :headers="headers" :items="items" item-key="name" :show-select="select === true">
        <template v-slot:item.file_name="{ item }">
          <a target="_blank" :href="config.apiUrl + '/api/' + type + '/' + id + '/file/' + item.id">{{
            item.file_name
          }}</a>
          <v-progress-linear v-if="loading_remove === item.id" indeterminate color="error"></v-progress-linear>
        </template>
        <template v-slot:item.size="{ item }">
          {{ parseFloat(item.size / 1024).toFixed(2) }} KB
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn :href="config.apiUrl + '/api/' + type + '/' + id + '/file/' + item.id" x-small text dark color="green">
            <v-icon>mdi-download-outline</v-icon>
          </v-btn>
          <v-btn :style="$props?.entity ? (($props.entity.status === 'completed' || $props.entity.status === 'delivered' || $props.entity.status === 'invoiced') && (!$store.getters.role.includes('admin') && !$store.getters.role.includes('accounting') && !$store.getters.role.includes('customer_support')) ? 'pointer-events:none' : '') : ''" x-small text dark color="error" @click="remove(item)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-data-table>
      <v-file-input v-model="files" color="deep-purple accent-4" counter label="Drag file here" multiple
        placeholder="Select your files" prepend-icon="" append-icon="" outlined :show-size="1000">
        <template v-slot:selection="{ index, text }">
          <v-chip v-if="index < 5" color="deep-purple accent-4" dark label small>
            {{ text }}
          </v-chip>

          <span v-else-if="index === 2" class="text-overline grey--text text--darken-3 mx-2">
            +{{ files.length - 2 }} File(s)
          </span>
        </template>
      </v-file-input>
      <v-btn dark color="green" @click="upload" :loading="loading"
        :disabled="(files && files.length == 0) ? true : false">
        <v-icon class="mr-3">mdi-cloud-upload</v-icon>
        Upload
      </v-btn>
    </vue-dropzone>
      <strong v-if="selectedFiles.length >= 1">{{ selectedFiles.length }} - Selected</strong>

    <v-progress-linear v-if="loading"
          indeterminate
          color="error"
        ></v-progress-linear>

  </div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone'
import axios from "@/plugins/axios";
import config from "@/config";
export default {
  name: 'Files',
  components: {
    vueDropzone: vue2Dropzone
  },
  props: {
    id: {
      type: Number,
      required: true
    },
    type: {
      type: String,
      required: true,
      default: null
    },
    select: {
      type: Boolean,
      required: false
    },
    entity: {
      type: Object,
      required: false,
      default: null
    }
  },
  data() {
    return {
      selectedFiles: [],
      dropzoneOptions: {
        clickable: false,
        url: config.apiUrl + '/api/' + this.type + '/' + this.id + '/file',
        thumbnailWidth: 150,
        maxFilesize: 99999999999,
        headers: { "My-Awesome-Header": "header value" }
      },
      config: config,
      headers: [
        { text: 'Name', value: 'file_name' },
        { text: 'Size', value: 'size' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      items: [],
      files: [],
      loading: false,
      loading_remove: null,
      disabled: true,
      show: true
    }
  },
  mounted() {
    this.getFiles()
  },
  computed: {
  },
  watch: {
    id: function (newVal, oldVal) {
      this.getFiles()
    },
    files: function (newVal, oldVal) {
      this.files
    },
    selectedFiles: function(newVal, oldVal) {
      this.$emit('selectedAtt', this.selectedFiles)
    }
  },
  methods: {
    applySelected () {
      this.$emit('selectedAtt', this.selectedFiles)
    },
    getFiles() {

      axios.get(config.apiUrl + '/api/' + this.type + '/' + this.id + '/file')
        .then((response) => {
          this.items = response.data
          this.show = false
          this.show = true
        })
    },
    upload() {
      this.loading = true
      for (let item of this.files) {
        var formData = new FormData();
        formData.append("file", item);
        axios.post(config.apiUrl + '/api/' + this.type + '/' + this.id + '/file', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(() => {
          axios.get(config.apiUrl + '/api/' + this.type + '/' + this.id + '/file')
            .then((response) => {
              this.items = response.data
              this.files = []
              this.loading = false
            })

        })
      }

    },
    remove(file) {
      if (!file || !('id' in file && 'file_name' in file)) {
        return console.error('The file object given is invalid!');
      }

      if (confirm(`Do you really want to delete this file: ${ file.file_name }?`)) {
        this.loading_remove = file.id
        axios.delete(config.apiUrl + '/api/' + this.type + '/' + this.id + '/file/' + file.id)
          .then((response) => {
            axios.get(config.apiUrl + '/api/' + this.type + '/' + this.id + '/file')
              .then((response) => {
                this.items = response.data
                this.files = []
                this.loading_remove = null
              })
          });
      }
    }
  }
}
</script>

<style>
.dz-preview {
  display: none !important;
}
</style>